import React from 'react'
import { LinkBox as OriginalLinkBox } from 'components/molecules'
import { LinkBoxSettings } from './LinkBoxSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import { useEditor } from '@craftjs/core'

export const defaultProps = {
  link: '#',
  pageLink: '',
}

export const LinkBox = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    };

    const { 
      link,
      pageLink,
    } = blockProps

    const { children } = props
    
    const { enabled } = useEditor((state) => ({
      enabled: state.options.enabled,
    }));

    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={'block-link-box ' + props.className}
      >
        <OriginalLinkBox 
          item={{
            titolo: children.props.children[0],
            descrizione: children.props.children[1],
            cta: children.props.children[2],
            link: pageLink || link || '#',
          }}
          editMode={enabled}
        />
      </div>
    );
  },
  defaultProps
);

LinkBox.craft = withCraft({
  name: 'LinkBox',
  defaultProps: defaultProps,
  settings: LinkBoxSettings,
});
