import React from 'react'
import useTrans from 'hooks/useTrans'
import { ChevronLeft } from 'components/atoms/Icons'
import {
  Button,
  FormField,
  FormInput,
  FormLayout,
  FormRadio,
  FormDate,
  FormStep,
  FormLegend,
  Help,
  FormCheckbox,
} from 'components/atoms'
import { StepProps } from '../FormRegistrazione.interfaces'
import CondizioniPrivacy from './CondizioniPrivacy'
import styles from './TuoBambino.module.sass'

const TuoBambino = ({ setSteps, formik }: StepProps) => {
  const t = useTrans()
  const isNato = formik.values.dati_personali.sono !== 'in_dolce_attesa'
  const yearFrom = new Date().getFullYear() - 18

  return (
    <FormStep title={t('Parlaci del tuo bambino')}>
      <FormStep.Content>
        <FormLayout>
          <FormField label={isNato ? t('Come si chiama?*') : t('Hai già deciso il nome?*')}>
            <FormInput
              onBlur={formik.handleBlur('tuo_bambino.nome_bambino')}
              value={formik.values.tuo_bambino?.nome_bambino}
              onChange={formik.handleChange('tuo_bambino.nome_bambino')}
            />
          </FormField>

          <FormField
            label={isNato ? t('Quando è nato?*') : t('Quando nascerà?')}
            errorMessage={formik.errors.tuo_bambino?.data_bambino && t('Inserire una data valida')}
            status={
              formik.touched.tuo_bambino?.data_bambino
                ? formik.errors.tuo_bambino?.data_bambino
                  ? 'error'
                  : 'success'
                : null
            }
          >
            <FormDate
              status={formik.errors.tuo_bambino?.data_bambino ? 'error' : ''}
              value={formik.values.tuo_bambino?.data_bambino}
              onChange={(value) => formik.setFieldValue('tuo_bambino.data_bambino', value)}
              yearFrom={yearFrom}
              yearNumber={20}
            />
          </FormField>
          <FormField
            label={isNato ? t('Il tuo bambino è*') : t('Il tuo bambino sarà*')}
            status={formik.errors.tuo_bambino?.sesso_bambino ? 'error' : null}
            errorMessage={formik.errors.tuo_bambino?.sesso_bambino}
            layout="full"
          >
            <FormRadio
              id="il_tuo_bambino_radio"
              value={formik.values.tuo_bambino?.sesso_bambino}
              onChange={formik.handleChange('tuo_bambino.sesso_bambino')}
              options={[
                { label: t('Maschio'), value: 'm', id: 'maschio' },
                { label: t('Femmina'), value: 'f', id: 'femmina' },
                { label: t('Non lo so ancora'), value: 'x', id: 'non lo so' },
              ]}
            />
          </FormField>

          <FormField
            label={isNato ? t('Il tuo bambino ha*') : t('Il tuo bambino avrà*')}
            status={
              formik.values.tuo_bambino?.gemelli
                ? formik.errors.tuo_bambino?.gemelli
                  ? 'error'
                  : 'success'
                : null
            }
            errorMessage={formik.errors.tuo_bambino?.gemelli}
            layout="full"
          >
            <FormCheckbox
              id="gemelli"
              checked={formik.values.tuo_bambino?.gemelli}
              onChange={formik.handleChange('tuo_bambino.gemelli')}
              label={t('Uno o più gemelli')}
            />
          </FormField>
        </FormLayout>
        <div className={styles.help}>
          <Help
            label={t('Hai altri figli?')}
            tooltip={{
              id: 'help-tooltip-altri-figli',
              label:
                'Non preoccuparti, potrai aggiungerli successivamente nella tua area personale.',
            }}
          />
        </div>
        <FormLayout>
          <CondizioniPrivacy formik={formik} />
        </FormLayout>
        <FormLegend label={t('* Campi obbligatori')} />
      </FormStep.Content>
      <FormStep.Buttons>
        <Button
          label={t('Indietro')}
          onClick={() => setSteps((prev) => prev - 1)}
          variant="secondary"
          icon={<ChevronLeft />}
          iconPos="left"
        />
        <Button
          label={t('Registrati')}
          type="submit"
          onClick={() => formik.handleSubmit()}
          disabled={!formik.isValid}
          variant="primary"
        />
      </FormStep.Buttons>
    </FormStep>
  )
}

export default TuoBambino
