import React from 'react'
import { BrandButton } from 'components/atoms'
import useTrans from "@hooks/useTrans";
import {useBrandsQuery} from "@gql/graphql";
import styles from './BrandCard.module.sass'


const BrandCard = () => {
  const t = useTrans()
  const { data } = useBrandsQuery()

  const title = t("Gli altri brand di Danone")
  const description=t("Clicca sui loghi per visitare i siti dei brand del gruppo Danone")
  const brands = data?.brands

  return (
    <div className={`${styles.brandCard}`}>
      <div className={styles.content}>
        {title && <p className={styles.title}>{title}</p>}
        {description && <div className={styles.description}>{description}</div>}

        <div className={styles.brandsContainer}>
          <ul className={styles.brandsWrapper} >
            {brands?.map(item =>
              <li>
                <BrandButton item={item} />
              </li>
            )}
          </ul>
        </div>

      </div>

    </div>
  )
}

export default BrandCard
