import React from 'react'
import { useEditor, ROOT_NODE } from '@craftjs/core'
import { isDisabled, getAncestors } from '../../../../utils/node'
import { Denied, Click } from 'react-pagebuilder/pb-components/icons'

export const Conditions = () => {
  const { selected, related, query } = useEditor((state) => {
    const selected = [...state.events.selected]?.[0]
    return {
      selected: selected,
      related: selected && state.nodes[selected].related,
    }
  })
  const isConditionsAllowed = () => {
    if (!selected) {
      return false
    }

    if (selected === ROOT_NODE) {
      return true
    }

    if (isDisabled(selected, query)) {
      return false
    }

    const ancestors = getAncestors(selected, query)

    const ddb = ancestors.find((nodeId) => {
      return query.node(nodeId).get().data.name === 'DynamicDataBlock'
    })
    if (ddb) {
      return false
    }

    return true
  }

  return (
    <>
      {isConditionsAllowed() && related.conditions ? (
        React.createElement(related.conditions)
      ) : (
        <div className="empty-message">
          {selected ? (
            <>
              <p>Conditions for the selected component are not allowed.</p>
              <Denied />
            </>
          ) : (
            <>
              <p>Click on a component to start editing.</p>
              <Click />
            </>
          )}
        </div>
      )}
    </>
  )
}
