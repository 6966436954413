import { Formik } from 'formik'
import useTrans from 'hooks/useTrans'
import React from 'react'
import dayjs from 'dayjs'
import * as yup from 'yup'
import { Button, FormDate, FormField, FormInput, FormLayout, FormRadio } from 'components/atoms'
import { FormCard } from 'components/molecules'
import { useUpdateUserMutation } from 'gql/graphql'

interface Props {
  initialValuesFromProps: any
  setInitialValuesFromProps: (e: any) => void
  onCancel?: () => void
}

const validationSchema = (t) =>
  yup.object().shape({
    nome: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    cognome: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    sesso: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    dataNascita: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    mobile: yup.number().nullable(),
  })

const FormEditDatiPersonali = ({
  initialValuesFromProps,
  setInitialValuesFromProps,
  onCancel,
}: Props) => {
  const [updateUtente, { loading }] = useUpdateUserMutation()
  const t = useTrans()
  return (
    <Formik
      validationSchema={validationSchema(t)}
      initialValues={{
        nome: initialValuesFromProps?.nome || null,
        cognome: initialValuesFromProps?.cognome || null,
        sesso: initialValuesFromProps?.sesso || null,
        dataNascita: initialValuesFromProps?.dataNascita || '1980-01-01',
        mobile: initialValuesFromProps?.mobile || null,
      }}
      onSubmit={(values) => {
        if (initialValuesFromProps) {
          updateUtente({
            variables: {
              input: {
                firstName: values?.nome,
                lastName: values?.cognome,
                fullName: `${values?.nome} ${values?.cognome}`,
                gender: values?.sesso,
                birthday: dayjs(values?.dataNascita).format('YYYY-MM-DD'),
                phoneMobile: values?.mobile?.toString(),
              },
            },
          }).then((res) => {
            console.log(res?.data?.updateUtente?.utente)
            setInitialValuesFromProps({ ...res?.data?.updateUtente?.utente, sesso: values?.sesso })
            onCancel()
          })
        }
      }}
    >
      {(props) => (
        <FormCard title={t('Modifica i tuoi dati account')} editMode>
          <FormCard.Content>
            <FormLayout>
              <FormField
                label={t('Nome')}
                status={props.touched?.nome ? (props.errors?.nome ? 'error' : 'success') : null}
                errorMessage={props?.errors?.nome}
              >
                <FormInput
                  onBlur={props.handleBlur('nome')}
                  placeholder={t('Nome')}
                  value={props.values?.nome}
                  onChange={props.handleChange('nome')}
                />
              </FormField>
              <FormField
                label={t('Cognome')}
                status={
                  props.touched?.cognome ? (props.errors?.cognome ? 'error' : 'success') : null
                }
                errorMessage={props?.errors?.cognome}
              >
                <FormInput
                  onBlur={props.handleBlur('cognome')}
                  placeholder={t('Cognome')}
                  value={props.values?.cognome}
                  onChange={props.handleChange('cognome')}
                />
              </FormField>

              <FormField
                label={t('Sono*')}
                errorMessage={props.errors?.sesso}
                status={props.errors?.sesso ? 'error' : null}
              >
                <FormRadio
                  id="sesso"
                  value={props.values?.sesso}
                  onChange={props.handleChange('sesso')}
                  options={[
                    {
                      id: 'mamma',
                      label: t('Mamma'),
                      value: 'F',
                    },
                    {
                      id: 'papà',
                      label: t('Papà'),
                      value: 'M',
                    },
                    {
                      id: 'in_dolce_attesa',
                      label: t('In dolce attesa'),
                      value: 'X',
                    },
                  ]}
                />
              </FormField>

              <FormField label={t('Cellulare')}>
                <FormInput
                  type="number"
                  onChange={props.handleChange('mobile')}
                  onBlur={props.handleBlur('mobile')}
                  value={props.values?.mobile}
                />
              </FormField>

              <FormField
                label="Data di nascita"
                errorMessage={props.errors?.dataNascita && t('Inserire una data valida')}
                status={
                  props.touched?.dataNascita
                    ? props.errors?.dataNascita
                      ? 'error'
                      : 'success'
                    : null
                }
              >
                <FormDate
                  status={props.errors?.dataNascita ? 'error' : ''}
                  value={props.values?.dataNascita}
                  onChange={(value) => props.setFieldValue('dataNascita', value)}
                />
              </FormField>
            </FormLayout>
          </FormCard.Content>
          <FormCard.Buttons>
            <Button label={t('Annulla')} onClick={onCancel} variant="ghost" />
            <Button
              loading={loading}
              type="submit"
              disabled={!props.isValid}
              onClick={() => props.handleSubmit()}
              label={t('Salva modifiche')}
              variant="primary"
            />
          </FormCard.Buttons>
        </FormCard>
      )}
    </Formik>
  )
}

export default FormEditDatiPersonali
