import React, { useState } from 'react'
import Link from 'next/link'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import useTrans from '@hooks/useTrans'
import { getIcon } from 'utils/icons'
import { MenuItemType } from 'utils/types'
import { ArrowLeft, ArrowRight } from '@components/atoms/Icons'
import { Button } from '@components/atoms'
import { ArticoloCard, GenericCard, ProdottoCard } from '@components/molecules'
import styles from './NavMobile.module.sass'
import { useSelector } from 'react-redux'
import useGTM from '../../../hooks/useGTM'

SwiperCore.use([Pagination])

interface Props {
  className?: string
}

interface NavBackButtonProps {
  label?: string
  onClick?: () => void
}

interface NavMobileItemProps {
  item: MenuItemType
  firstLevelItem?: MenuItemType
  main?: boolean
  onClick?: () => void
}

interface NavFinalLinkProps {
  item: MenuItemType
  main?: boolean
  onClick?: () => void
}

const NavMobileItem = (props: NavMobileItemProps) => {
  const {
    item = undefined,
    main = false,
    onClick = () => undefined,
    firstLevelItem = undefined,
  } = props
  const { trackingGAevent } = useGTM()

  const classNames = `${styles.menuItem} ${main ? styles['menuItem--main'] : ''}`
  const content = (
    <>
      {main && (
        <span className={styles.menuItemIcon}>
          {/* Sempre presente nel main per avere il testo allineato anche se manca l'icona */}
          {getIcon(item.style)}
        </span>
      )}
      <span className={styles.menuItemLabel}>{item.name}</span>
    </>
  )

  return item && ((item.children?.length > 0 && onClick) || item.url) ? (
    <nav
      className={`${styles.menuItemWrap} ${
        item.style ? styles[`menuItemWrap--${item.style}`] : ''
      }`}
    >
      {item.children?.length > 0 && onClick ? (
        <button type="button" className={classNames} onClick={onClick}>
          {content}
          <span className={styles.menuItemArrow}>
            <ArrowRight />
          </span>
        </button>
      ) : (
        <Link legacyBehavior href={item.url}>
          <a
            className={classNames}
            onClick={() => trackingGAevent('1', 'main_menu', firstLevelItem?.name, item.name)}
          >
            {content}
          </a>
        </Link>
      )}
    </nav>
  ) : (
    <></>
  )
}

const NavBackButton = (props: NavBackButtonProps) => {
  const { label = '', onClick = () => undefined } = props

  return (
    <button type="button" className={styles.back} onClick={onClick}>
      <span className={styles.backArrow}>
        <ArrowLeft />
      </span>
      <span className={styles.backLabel}>{label}</span>
    </button>
  )
}

const NavFinalLink = (props: NavFinalLinkProps) => {
  const { item = undefined } = props
  const { trackingGAevent } = useGTM()

  return item && item.url ? (
    <Link legacyBehavior href={item.url}>
      <a
        className={styles.finalLink}
        onClick={() =>
          trackingGAevent('1', 'main_menu', item.ctaLabel ?? item.name, item.ctaLabel ?? item.name)
        }
      >
        {item.ctaLabel ? item.ctaLabel : item.name}
      </a>
    </Link>
  ) : (
    <></>
  )
}

const NavMobile = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()

  const [activeMainItem, setActiveMainItem] = useState<MenuItemType | null>(null)
  const [activeSecondaryItem, setActiveSecondaryItem] = useState<MenuItemType | null>(null)
  const { trackingGAevent } = useGTM()

  const { menu } = useSelector((state) => state.menu)
  const navMenu = menu?.find((item) => item.key === 'main')
  const navMenuMobilePlus = menu?.find((item) => item.key === 'mobile_plus')
  const topMenu = menu?.find((item) => item.key === 'top')

  const topMenuMobileItems =
    topMenu?.children?.length > 0 ? topMenu.children.filter((x) => x.key !== 'area-riservata') : []

  return (
    <nav className={`${styles.navMobile} navMobile ${className}`}>
      <div
        className={`${styles.panel} panelmain ${styles['panel--main']} ${
          activeMainItem ? styles['panel--inactive'] : ''
        }`}
      >
        {/* Slider */}
        {topMenuMobileItems.length > 0 && (
          <div className={styles.topMenu}>
            {!!topMenu.name && <p className={styles.topMenuTitle}>{topMenu.name}</p>}
            <div className={styles.slider}>
              <Swiper
                slidesPerView={1.2}
                spaceBetween={15}
                pagination={{ clickable: true }}
                watchOverflow
                breakpoints={{
                  480: {
                    slidesPerView: 2.2,
                  },
                }}
              >
                {topMenuMobileItems
                  .filter((item) => item.key !== 'newsletterBox')
                  .map((item) => (
                    <SwiperSlide key={item.pk}>
                      <Link legacyBehavior href={item.url}>
                        <a
                          className={styles.sliderItem}
                          onClick={() => trackingGAevent('1', 'main_menu', item.name, item.name)}
                        >
                          {!!item.style && (
                            <span className={styles.sliderItemIcon}>{getIcon(item.style)}</span>
                          )}
                          <span className={styles.sliderItemLabel}>{item.name}</span>
                        </a>
                      </Link>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        )}

        {/* Menu main - livello 1 */}

        {navMenu?.children?.length > 0 && (
          <div className={styles.menu}>
            {navMenu.children.map((item) => (
              <NavMobileItem
                key={item.pk}
                main
                item={item}
                onClick={() => setActiveMainItem(item)}
              />
            ))}
          </div>
        )}

        {/* Menu aggiuntivo per il mobile */}
        {navMenuMobilePlus?.children?.length > 0 && (
          <div className={styles.menuPlus}>
            {navMenuMobilePlus?.children.map((item) => (
              <Button key={item.pk} label={item.name} variant="secondary" href={item.url} />
            ))}
          </div>
        )}

        {/* Contattaci FAQ */}
      </div>
      {navMenu?.children.map((item) => (
        <div
          className={`${styles.panel} ${styles['panel--secondary']} ${
            activeMainItem === item ? styles['panel--open'] : ''
          } ${activeSecondaryItem ? styles['panel--inactive'] : ''}`}
        >
          <>
            <NavBackButton
              label={t('Torna al menu principale')}
              onClick={() => setActiveMainItem(null)}
            />
            {item.url && <NavFinalLink item={item} />}
            {item.key === 'prodotti' ? (
              <div className={styles.prodotti}>
                {item.children.map((item2) => (
                  <Link legacyBehavior href={item2.url} key={item2.pk}>
                    <a className={styles.prodottiItem}>
                      <ProdottoCard
                        prodotto={{
                          pk: item2.pk,
                          titolo: item2.name,
                          descrizione: item2.description,
                          mainImage: item2.image,
                        }}
                        size="sm"
                        noAnimation
                      />
                    </a>
                  </Link>
                ))}
                <div className={`${styles.prodottiItem} ${styles['prodottiItem--final']}`}>
                  <div className={styles.prodottiFinalWrap}>
                    <a
                      className={styles.prodottiFinalLink}
                      href="https://www.mymellinshop.it/"
                      target="_blank"
                      rel="noopener norefferer"
                    >
                      <span className={styles.prodottiFinalLinkContent}>
                        <span className={styles.prodottiFinalLinkLabel}>
                          {t('Tutti i prodotti su mymellinshop')}
                        </span>
                        <span className={styles.prodottiFinalLinkIcon}>
                          <ArrowRight />
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            ) : item.key === 'parto-riparto' && item.children?.length > 0 ? (
              <div className={`${styles.menu} ${item.key ? styles[`key--${item.key}`] : ''}`}>
                {item.children?.map((item2) => (
                  <NavMobileItem key={item2.pk} item={item2} />
                ))}
              </div>
            ) : (
              <>
                {item.children?.map((item2) => (
                  <>
                    {['menu2levels', 'menu'].indexOf(item2.style?.split('_')[0]) > -1 &&
                      item2.children?.length > 0 && (
                        <div
                          className={`${styles.menu} ${
                            item2.key ? styles[`key--${item2.key}`] : ''
                          }`}
                        >
                          {item2.children.map((item3) => (
                            <NavMobileItem
                              key={item3.pk}
                              item={item3}
                              firstLevelItem={item2}
                              onClick={() => setActiveSecondaryItem(item3)}
                            />
                          ))}
                        </div>
                      )}
                    {item2.style?.split('_')[0] === 'box' && item2.children?.length > 0 && (
                      <div
                        className={`${styles.boxes} ${
                          item2.key ? styles[`key--${item2.key}`] : ''
                        }`}
                      >
                        {item2.children.map((item3) => (
                          <GenericCard item={item3} key={item3.pk} />
                        ))}
                      </div>
                    )}
                    {item2.style?.split('_')[0] === 'articoli' && item2.children?.length > 0 && (
                      <div
                        className={`${styles.articoliSlider} ${
                          item2.key ? styles[`key--${item2.key}`] : ''
                        }`}
                      >
                        <Swiper
                          slidesPerView={1.2}
                          spaceBetween={16}
                          breakpoints={{
                            480: {
                              slidesPerView: 2,
                            },
                          }}
                        >
                          {item2.children
                            .filter((item3) => item3.key !== 'contattaci')
                            .map((item3) => (
                              <SwiperSlide>
                                <ArticoloCard articolo={item3.articolo} size="sm" />
                              </SwiperSlide>
                            ))}
                        </Swiper>
                      </div>
                    )}
                  </>
                ))}
              </>
            )}
          </>
        </div>
      ))}
      {navMenu?.children.map((item) =>
        item.children?.map((item2) =>
          item2.children?.map((item3) => (
            <div
              className={`${styles.panel} ${styles['panel--tertiary']} ${
                activeSecondaryItem === item3 ? styles['panel--open'] : ''
              }`}
            >
              {item3 && (
                <>
                  <NavBackButton
                    label={t('Torna indietro')}
                    onClick={() => setActiveSecondaryItem(null)}
                  />
                  {item3.url && <NavFinalLink item={item3} />}
                  {item3.children?.length > 0 && (
                    <div className={styles.menu}>
                      {item3.children.map((item4) => (
                        <NavMobileItem item={item4} key={item4.pk} />
                      ))}
                    </div>
                  )}
                </>
              )}
              {/* Terzo livello menu Main == uguale a panel secondary come stile
                - back
                - link all
                - voci
                - altri box (no ciuccio)
                oppure
                - prodotti se tendina prodotti
              */}
            </div>
          ))
        )
      )}
    </nav>
  )
}

export default NavMobile
