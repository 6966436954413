import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import useTrans from '@hooks/useTrans'
import { initializeApollo } from 'gql/client'
import { WContainer } from '@components/atoms'
import styles from './SearchResultsDropdown.module.sass'
import { SearchResultItem } from '@components/molecules'
import { SEARCH, AUTOCOMPLETE } from 'utils/queries'
import useGTM from '../../../hooks/useGTM'

interface Props {
  query?: string
  open?: boolean
  setOpen?: (v: boolean) => void
  className?: string
}

const SearchResultsDropdown = (props: Props) => {
  const client = initializeApollo()
  const [risultati, setRisultati] = useState([])
  const [suggerimenti, setSuggerimenti] = useState([])

  const { query = '', open = false, setOpen = () => undefined, className = '' } = props

  const t = useTrans()
  const { trackingGAevent } = useGTM()
  const regex = new RegExp('(' + query + ')', 'gi')

  useEffect(() => {
    loadRisultati(query)
    loadSuggestions(query)
  }, [query])

  const loadRisultati = async (query: string) => {
    let risultati = []

    if (query != '') {
      const {
        data: { search },
      } = await client.query({
        query: SEARCH,
        variables: {
          query: query,
          first: 2, // ho limitato a 2 il numero di risultati
        },
      })

      risultati = Object.assign({}, search)
      risultati = risultati.edges.map((item) => item.node)
    }

    setRisultati(risultati)
  }

  const loadSuggestions = async (query: string) => {
    let risultati = []

    if (query != '') {
      const {
        data: { autocomplete },
      } = await client.query({
        query: AUTOCOMPLETE,
        variables: {
          query: query,
        },
      })

      risultati = autocomplete.map((item) => {
        let title = ''

        item = item.object

        if (item) {
          if (item.domanda) title = item.domanda
          else title = item.titolo
        }

        return {
          pk: item.pk,
          titolo: title,
          url: item.url,
        }
      })
    }

    setSuggerimenti(risultati)
  }

  // default arguments
  const argomenti = [
    { pk: 1, titolo: 'Gravidanza', url: '/c/gravidanza' },
    { pk: 2, titolo: 'Primi mesi', url: '/c/primi-mesi' },
    { pk: 3, titolo: 'Svezzamento', url: '/c/svezzamento' },
    { pk: 4, titolo: 'Dopo il primo anno', url: '/c/dopo-primo-anno' },
  ]

  return (
    <>
      {open && <div className={styles.overlay} onClick={() => setOpen(false)} />}
      <div
        className={`${styles.searchResultsDropdown} ${
          open ? styles['searchResultsDropdown--open'] : ''
        } ${className}`}
      >
        <WContainer>
          <div className={styles.layout}>
            {query ? (
              <>
                {suggerimenti.length > 0 && (
                  <div className={styles.simpleResults}>
                    <p className={styles.title}>{t('Risultati suggeriti')}</p>
                    <div className={styles.simpleList}>
                      {suggerimenti.map((x) => (
                        <Link legacyBehavior key={x.pk} href={x.url}>
                          <a
                            className={styles.simpleItem}
                            dangerouslySetInnerHTML={{
                              __html: x.titolo.replace(regex, '<span>$1</span>'),
                            }}
                            onClick={() => trackingGAevent('10', 'search', x.titolo)}
                          />
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
                {risultati.length > 0 && (
                  <div className={styles.results}>
                    <div className={styles.list}>
                      {risultati.map((x) => (
                        <SearchResultItem
                          size="sm"
                          item={x}
                          key={`${x.modelName}_${x.object.pk}`}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className={styles.simpleResults}>
                <p className={styles.title}>{t('Gli argomenti più ricercati')}</p>
                <div className={styles.simpleList}>
                  {argomenti.map((x) => (
                    <Link legacyBehavior key={x.pk} href={x.url}>
                      <a
                        className={styles.simpleItem}
                        onClick={() => trackingGAevent('10', 'search', x.titolo)}
                      >
                        {x.titolo}
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
        </WContainer>
      </div>
    </>
  )
}

export default SearchResultsDropdown
