export const PROTOCOL_SCHEMA = process.env.NODE_ENV === 'production' ? 'https' : 'http'
export const DOMAIN = process.env.NODE_ENV === 'production' ? 'https://www.mellin.it' : 'localhost:3000'
export const BRAND = 'Mellin'
export const LOGO_URL = DOMAIN + "/images/logo.png"

export const TIPO_1 = '1'
export const TIPO_2 = '2'
export const TIPO_3 = '3'

export const PAGE_SIZE_TIPO_1 = 11
export const PAGE_SIZE_TIPO_3 = 12

export const PAGE_SIZE = {
  1: PAGE_SIZE_TIPO_1,
  3: PAGE_SIZE_TIPO_3,
}

export const PAGINA_TEMPLATE_LANDING = 'landing'

export const RICERCA_ELEMENTS_PER_PAGE = 9

// Vincoli:
// - nelle curve deve esserci un parametro con x corrispondente all'ultimo valore dei tick (ora 36) => serve per calcolare la posizione della legenda

export const curvaLunghezzaFData = [
  {
    id: 'curva-l-f-1',
    data: [
      { x: 0, y: 45.644 },
      { x: 1, y: 49.96 },
      { x: 2, y: 53.155 },
      { x: 3, y: 55.742 },
      { x: 3.3333333333333335, y: 56.503 },
      { x: 4, y: 57.904 },
      { x: 5, y: 59.737 },
      { x: 6, y: 61.336 },
      { x: 7, y: 62.791 },
      { x: 8, y: 64.148 },
      { x: 8.233333333333333, y: 64.455 },
      { x: 9, y: 65.436 },
      { x: 9.6, y: 66.179 },
      { x: 10, y: 66.665 },
      { x: 11, y: 67.845 },
      { x: 11.433333333333334, y: 68.341 },
      { x: 12, y: 68.978 },
      { x: 13, y: 70.069 },
      { x: 14, y: 71.122 },
      { x: 15, y: 72.139 },
      { x: 16, y: 73.125 },
      { x: 17, y: 74.08 },
      { x: 18, y: 75.008 },
      { x: 19, y: 75.909 },
      { x: 20, y: 76.786 },
      { x: 21, y: 77.64 },
      { x: 22, y: 78.473 },
      { x: 23, y: 79.283 },
      { x: 24, y: 80.075 },
      { x: 25, y: 80.148 },
      { x: 26, y: 80.902 },
      { x: 27, y: 81.639 },
      { x: 28, y: 82.357 },
      { x: 29, y: 83.059 },
      { x: 30, y: 83.746 },
      { x: 31, y: 84.416 },
      { x: 32, y: 85.07 },
      { x: 33, y: 85.712 },
      { x: 34, y: 86.344 },
      { x: 35, y: 86.962 },
      { x: 36, y: 87.573 },
      { x: 37, y: 88.173 },
    ],
  },
  {
    id: 'curva-l-f-2',
    data: [
      { x: 0, y: 47.217 },
      { x: 1, y: 51.609 },
      { x: 2, y: 54.872 },
      { x: 3, y: 57.517 },
      { x: 3.3333333333333335, y: 58.296 },
      { x: 4, y: 59.729 },
      { x: 5, y: 61.606 },
      { x: 6, y: 63.246 },
      { x: 7, y: 64.741 },
      { x: 8, y: 66.14 },
      { x: 8.233333333333333, y: 66.456 },
      { x: 9, y: 67.47 },
      { x: 9.6, y: 68.239 },
      { x: 10, y: 68.743 },
      { x: 11, y: 69.966 },
      { x: 11.433333333333334, y: 70.482 },
      { x: 12, y: 71.145 },
      { x: 13, y: 72.281 },
      { x: 14, y: 73.379 },
      { x: 15, y: 74.443 },
      { x: 16, y: 75.473 },
      { x: 17, y: 76.474 },
      { x: 18, y: 77.448 },
      { x: 19, y: 78.394 },
      { x: 20, y: 79.317 },
      { x: 21, y: 80.216 },
      { x: 22, y: 81.093 },
      { x: 23, y: 81.948 },
      { x: 24, y: 82.784 },
      { x: 25, y: 82.9 },
      { x: 26, y: 83.697 },
      { x: 27, y: 84.477 },
      { x: 28, y: 85.238 },
      { x: 29, y: 85.981 },
      { x: 30, y: 86.708 },
      { x: 31, y: 87.419 },
      { x: 32, y: 88.113 },
      { x: 33, y: 88.794 },
      { x: 34, y: 89.464 },
      { x: 35, y: 90.121 },
      { x: 36, y: 90.769 },
      { x: 37, y: 91.406 },
    ],
  },
  {
    id: 'curva-l-f-3',
    data: [
      { x: 0, y: 49.148 },
      { x: 1, y: 53.633 },
      { x: 2, y: 56.981 },
      { x: 3, y: 59.695 },
      { x: 3.3333333333333335, y: 60.496 },
      { x: 4, y: 61.969 },
      { x: 5, y: 63.9 },
      { x: 6, y: 65.591 },
      { x: 7, y: 67.136 },
      { x: 8, y: 68.586 },
      { x: 8.233333333333333, y: 68.913 },
      { x: 9, y: 69.967 },
      { x: 9.6, y: 70.768 },
      { x: 10, y: 71.293 },
      { x: 11, y: 72.57 },
      { x: 11.433333333333334, y: 73.11 },
      { x: 12, y: 73.803 },
      { x: 13, y: 74.996 },
      { x: 14, y: 76.15 },
      { x: 15, y: 77.27 },
      { x: 16, y: 78.356 },
      { x: 17, y: 79.413 },
      { x: 18, y: 80.442 },
      { x: 19, y: 81.445 },
      { x: 20, y: 82.423 },
      { x: 21, y: 83.378 },
      { x: 22, y: 84.31 },
      { x: 23, y: 85.22 },
      { x: 24, y: 86.109 },
      { x: 25, y: 86.278 },
      { x: 26, y: 87.129 },
      { x: 27, y: 87.961 },
      { x: 28, y: 88.774 },
      { x: 29, y: 89.568 },
      { x: 30, y: 90.345 },
      { x: 31, y: 91.105 },
      { x: 32, y: 91.849 },
      { x: 33, y: 92.578 },
      { x: 34, y: 93.294 },
      { x: 35, y: 93.998 },
      { x: 36, y: 94.691 },
      { x: 37, y: 95.375 },
    ],
  },
  {
    id: 'curva-l-f-4',
    data: [
      { x: 0, y: 51.078 },
      { x: 1, y: 55.657 },
      { x: 2, y: 59.089 },
      { x: 3, y: 61.874 },
      { x: 3.3333333333333335, y: 62.696 },
      { x: 4, y: 64.208 },
      { x: 5, y: 66.194 },
      { x: 6, y: 67.936 },
      { x: 7, y: 69.53 },
      { x: 8, y: 71.031 },
      { x: 8.233333333333333, y: 71.37 },
      { x: 9, y: 72.463 },
      { x: 9.6, y: 73.297 },
      { x: 10, y: 73.843 },
      { x: 11, y: 75.174 },
      { x: 11.433333333333334, y: 75.738 },
      { x: 12, y: 76.462 },
      { x: 13, y: 77.711 },
      { x: 14, y: 78.921 },
      { x: 15, y: 80.096 },
      { x: 16, y: 81.239 },
      { x: 17, y: 82.353 },
      { x: 18, y: 83.437 },
      { x: 19, y: 84.495 },
      { x: 20, y: 85.529 },
      { x: 21, y: 86.539 },
      { x: 22, y: 87.526 },
      { x: 23, y: 88.491 },
      { x: 24, y: 89.434 },
      { x: 25, y: 89.657 },
      { x: 26, y: 90.56 },
      { x: 27, y: 91.444 },
      { x: 28, y: 92.31 },
      { x: 29, y: 93.155 },
      { x: 30, y: 93.982 },
      { x: 31, y: 94.791 },
      { x: 32, y: 95.584 },
      { x: 33, y: 96.361 },
      { x: 34, y: 97.124 },
      { x: 35, y: 97.875 },
      { x: 36, y: 98.614 },
      { x: 37, y: 99.344 },
    ],
  },
  {
    id: 'curva-l-f-5',
    data: [
      { x: 0, y: 52.651 },
      { x: 1, y: 57.305 },
      { x: 2, y: 60.806 },
      { x: 3, y: 63.648 },
      { x: 3.3333333333333335, y: 64.488 },
      { x: 4, y: 66.033 },
      { x: 5, y: 68.063 },
      { x: 6, y: 69.846 },
      { x: 7, y: 71.48 },
      { x: 8, y: 73.023 },
      { x: 8.233333333333333, y: 73.372 },
      { x: 9, y: 74.497 },
      { x: 9.6, y: 75.358 },
      { x: 10, y: 75.92 },
      { x: 11, y: 77.295 },
      { x: 11.433333333333334, y: 77.879 },
      { x: 12, y: 78.628 },
      { x: 13, y: 79.923 },
      { x: 14, y: 81.179 },
      { x: 15, y: 82.4 },
      { x: 16, y: 83.588 },
      { x: 17, y: 84.747 },
      { x: 18, y: 85.877 },
      { x: 19, y: 86.981 },
      { x: 20, y: 88.059 },
      { x: 21, y: 89.115 },
      { x: 22, y: 90.147 },
      { x: 23, y: 91.156 },
      { x: 24, y: 92.143 },
      { x: 25, y: 92.409 },
      { x: 26, y: 93.356 },
      { x: 27, y: 94.282 },
      { x: 28, y: 95.19 },
      { x: 29, y: 96.078 },
      { x: 30, y: 96.945 },
      { x: 31, y: 97.794 },
      { x: 32, y: 98.627 },
      { x: 33, y: 99.443 },
      { x: 34, y: 100.244 },
      { x: 35, y: 101.034 },
      { x: 36, y: 101.81 },
      { x: 37, y: 102.577 },
    ],
  },
]

export const curvaLunghezzaMData = [
  {
    id: 'curva-l-m-1',
    data: [
      { x: 0, y: 46.324 },
      { x: 1, y: 51.005 },
      { x: 2, y: 54.57 },
      { x: 3, y: 57.47 },
      { x: 4, y: 59.848 },
      { x: 5, y: 61.802 },
      { x: 6, y: 63.463 },
      { x: 7, y: 64.938 },
      { x: 8, y: 66.298 },
      { x: 9, y: 67.584 },
      { x: 10, y: 68.809 },
      { x: 11, y: 69.975 },
      { x: 12, y: 71.089 },
      { x: 13, y: 72.157 },
      { x: 14, y: 73.185 },
      { x: 15, y: 74.175 },
      { x: 16, y: 75.132 },
      { x: 17, y: 76.057 },
      { x: 18, y: 76.955 },
      { x: 19, y: 77.824 },
      { x: 20, y: 78.666 },
      { x: 21, y: 79.485 },
      { x: 22, y: 80.279 },
      { x: 23, y: 81.053 },
      { x: 24, y: 81.81 },
      { x: 25, y: 81.846 },
      { x: 25.633333333333333, y: 82.305 },
      { x: 26, y: 82.565 },
      { x: 27, y: 83.265 },
      { x: 28, y: 83.946 },
      { x: 29, y: 84.608 },
      { x: 30, y: 85.252 },
      { x: 31, y: 85.879 },
      { x: 32, y: 86.491 },
      { x: 33, y: 87.087 },
      { x: 34, y: 87.673 },
      { x: 35, y: 88.249 },
      { x: 36, y: 88.816 },
      { x: 37, y: 89.377 },
    ],
  },
  {
    id: 'curva-l-m-2',
    data: [
      { x: 0, y: 47.922 },
      { x: 1, y: 52.648 },
      { x: 2, y: 56.258 },
      { x: 3, y: 59.195 },
      { x: 4, y: 61.603 },
      { x: 5, y: 63.583 },
      { x: 6, y: 65.268 },
      { x: 7, y: 66.768 },
      { x: 8, y: 68.157 },
      { x: 9, y: 69.475 },
      { x: 10, y: 70.734 },
      { x: 11, y: 71.936 },
      { x: 12, y: 73.089 },
      { x: 13, y: 74.198 },
      { x: 14, y: 75.268 },
      { x: 15, y: 76.302 },
      { x: 16, y: 77.303 },
      { x: 17, y: 78.275 },
      { x: 18, y: 79.22 },
      { x: 19, y: 80.137 },
      { x: 20, y: 81.028 },
      { x: 21, y: 81.896 },
      { x: 22, y: 82.74 },
      { x: 23, y: 83.565 },
      { x: 24, y: 84.371 },
      { x: 25, y: 84.459 },
      { x: 25.633333333333333, y: 84.949 },
      { x: 26, y: 85.228 },
      { x: 27, y: 85.977 },
      { x: 28, y: 86.707 },
      { x: 29, y: 87.416 },
      { x: 30, y: 88.107 },
      { x: 31, y: 88.78 },
      { x: 32, y: 89.435 },
      { x: 33, y: 90.075 },
      { x: 34, y: 90.703 },
      { x: 35, y: 91.319 },
      { x: 36, y: 91.926 },
      { x: 37, y: 92.525 },
    ],
  },
  {
    id: 'curva-l-m-3',
    data: [
      { x: 0, y: 49.884 },
      { x: 1, y: 54.665 },
      { x: 2, y: 58.33 },
      { x: 3, y: 61.312 },
      { x: 4, y: 63.758 },
      { x: 5, y: 65.769 },
      { x: 6, y: 67.484 },
      { x: 7, y: 69.015 },
      { x: 8, y: 70.438 },
      { x: 9, y: 71.795 },
      { x: 10, y: 73.096 },
      { x: 11, y: 74.343 },
      { x: 12, y: 75.543 },
      { x: 13, y: 76.703 },
      { x: 14, y: 77.825 },
      { x: 15, y: 78.912 },
      { x: 16, y: 79.969 },
      { x: 17, y: 80.998 },
      { x: 18, y: 82 },
      { x: 19, y: 82.976 },
      { x: 20, y: 83.927 },
      { x: 21, y: 84.855 },
      { x: 22, y: 85.761 },
      { x: 23, y: 86.648 },
      { x: 24, y: 87.516 },
      { x: 25, y: 87.667 },
      { x: 25.633333333333333, y: 88.195 },
      { x: 26, y: 88.497 },
      { x: 27, y: 89.307 },
      { x: 28, y: 90.096 },
      { x: 29, y: 90.864 },
      { x: 30, y: 91.612 },
      { x: 31, y: 92.34 },
      { x: 32, y: 93.05 },
      { x: 33, y: 93.743 },
      { x: 34, y: 94.421 },
      { x: 35, y: 95.088 },
      { x: 36, y: 95.743 },
      { x: 37, y: 96.389 },
    ],
  },
  {
    id: 'curva-l-m-4',
    data: [
      { x: 0, y: 51.846 },
      { x: 1, y: 56.681 },
      { x: 2, y: 60.402 },
      { x: 3, y: 63.428 },
      { x: 4, y: 65.913 },
      { x: 5, y: 67.955 },
      { x: 6, y: 69.699 },
      { x: 7, y: 71.262 },
      { x: 8, y: 72.72 },
      { x: 9, y: 74.115 },
      { x: 10, y: 75.458 },
      { x: 11, y: 76.75 },
      { x: 12, y: 77.998 },
      { x: 13, y: 79.208 },
      { x: 14, y: 80.382 },
      { x: 15, y: 81.523 },
      { x: 16, y: 82.634 },
      { x: 17, y: 83.72 },
      { x: 18, y: 84.78 },
      { x: 19, y: 85.815 },
      { x: 20, y: 86.826 },
      { x: 21, y: 87.814 },
      { x: 22, y: 88.783 },
      { x: 23, y: 89.731 },
      { x: 24, y: 90.661 },
      { x: 25, y: 90.874 },
      { x: 25.633333333333333, y: 91.441 },
      { x: 26, y: 91.766 },
      { x: 27, y: 92.636 },
      { x: 28, y: 93.484 },
      { x: 29, y: 94.312 },
      { x: 30, y: 95.116 },
      { x: 31, y: 95.9 },
      { x: 32, y: 96.664 },
      { x: 33, y: 97.411 },
      { x: 34, y: 98.14 },
      { x: 35, y: 98.856 },
      { x: 36, y: 99.561 },
      { x: 37, y: 100.253 },
    ],
  },
  {
    id: 'curva-l-m-5',
    data: [
      { x: 0, y: 53.445 },
      { x: 1, y: 58.324 },
      { x: 2, y: 62.09 },
      { x: 3, y: 65.153 },
      { x: 4, y: 67.668 },
      { x: 5, y: 69.736 },
      { x: 6, y: 71.505 },
      { x: 7, y: 73.092 },
      { x: 8, y: 74.578 },
      { x: 9, y: 76.005 },
      { x: 10, y: 77.383 },
      { x: 11, y: 78.711 },
      { x: 12, y: 79.997 },
      { x: 13, y: 81.249 },
      { x: 14, y: 82.465 },
      { x: 15, y: 83.65 },
      { x: 16, y: 84.806 },
      { x: 17, y: 85.938 },
      { x: 18, y: 87.045 },
      { x: 19, y: 88.127 },
      { x: 20, y: 89.188 },
      { x: 21, y: 90.225 },
      { x: 22, y: 91.244 },
      { x: 23, y: 92.242 },
      { x: 24, y: 93.223 },
      { x: 25, y: 93.487 },
      { x: 25.633333333333333, y: 94.085 },
      { x: 26, y: 94.429 },
      { x: 27, y: 95.348 },
      { x: 28, y: 96.245 },
      { x: 29, y: 97.121 },
      { x: 30, y: 97.971 },
      { x: 31, y: 98.801 },
      { x: 32, y: 99.609 },
      { x: 33, y: 100.399 },
      { x: 34, y: 101.17 },
      { x: 35, y: 101.927 },
      { x: 36, y: 102.671 },
      { x: 37, y: 103.401 },
    ],
  },
]

export const curvaPesoFData = [
  {
    id: 'curva-w-f-1',
    data: [
      { x: 0, y: 2.44 },
      { x: 1, y: 3.203 },
      { x: 2, y: 3.985 },
      { x: 3, y: 4.582 },
      { x: 3.3333333333333335, y: 4.754 },
      { x: 4, y: 5.063 },
      { x: 5, y: 5.457 },
      { x: 6, y: 5.786 },
      { x: 7, y: 6.067 },
      { x: 8, y: 6.315 },
      { x: 8.233333333333333, y: 6.369 },
      { x: 9, y: 6.537 },
      { x: 9.6, y: 6.66 },
      { x: 10, y: 6.74 },
      { x: 11, y: 6.929 },
      { x: 11.433333333333334, y: 7.009 },
      { x: 12, y: 7.111 },
      { x: 13, y: 7.286 },
      { x: 14, y: 7.458 },
      { x: 15, y: 7.628 },
      { x: 16, y: 7.796 },
      { x: 17, y: 7.963 },
      { x: 18, y: 8.129 },
      { x: 19, y: 8.294 },
      { x: 20, y: 8.457 },
      { x: 21, y: 8.619 },
      { x: 22, y: 8.781 },
      { x: 23, y: 8.943 },
      { x: 24, y: 9.105 },
      { x: 25, y: 9.266 },
      { x: 26, y: 9.427 },
      { x: 27, y: 9.586 },
      { x: 28, y: 9.742 },
      { x: 29, y: 9.896 },
      { x: 30, y: 10.046 },
      { x: 31, y: 10.193 },
      { x: 32, y: 10.336 },
      { x: 33, y: 10.477 },
      { x: 34, y: 10.616 },
      { x: 35, y: 10.754 },
      { x: 36, y: 10.89 },
      { x: 37, y: 11.024 },
    ],
  },
  {
    id: 'curva-w-f-2',
    data: [
      { x: 0, y: 2.779 },
      { x: 1, y: 3.612 },
      { x: 2, y: 4.457 },
      { x: 3, y: 5.102 },
      { x: 3.3333333333333335, y: 5.287 },
      { x: 4, y: 5.622 },
      { x: 5, y: 6.049 },
      { x: 6, y: 6.405 },
      { x: 7, y: 6.712 },
      { x: 8, y: 6.983 },
      { x: 8.233333333333333, y: 7.042 },
      { x: 9, y: 7.227 },
      { x: 9.6, y: 7.363 },
      { x: 10, y: 7.45 },
      { x: 11, y: 7.659 },
      { x: 11.433333333333334, y: 7.746 },
      { x: 12, y: 7.858 },
      { x: 13, y: 8.052 },
      { x: 14, y: 8.241 },
      { x: 15, y: 8.427 },
      { x: 16, y: 8.612 },
      { x: 17, y: 8.795 },
      { x: 18, y: 8.977 },
      { x: 19, y: 9.158 },
      { x: 20, y: 9.338 },
      { x: 21, y: 9.518 },
      { x: 22, y: 9.697 },
      { x: 23, y: 9.876 },
      { x: 24, y: 10.055 },
      { x: 25, y: 10.235 },
      { x: 26, y: 10.414 },
      { x: 27, y: 10.591 },
      { x: 28, y: 10.766 },
      { x: 29, y: 10.938 },
      { x: 30, y: 11.107 },
      { x: 31, y: 11.273 },
      { x: 32, y: 11.436 },
      { x: 33, y: 11.596 },
      { x: 34, y: 11.754 },
      { x: 35, y: 11.911 },
      { x: 36, y: 12.067 },
      { x: 37, y: 12.222 },
    ],
  },
  {
    id: 'curva-w-f-3',
    data: [
      { x: 0, y: 3.232 },
      { x: 1, y: 4.172 },
      { x: 2, y: 5.105 },
      { x: 3, y: 5.818 },
      { x: 3.3333333333333335, y: 6.023 },
      { x: 4, y: 6.394 },
      { x: 5, y: 6.867 },
      { x: 6, y: 7.265 },
      { x: 7, y: 7.609 },
      { x: 8, y: 7.915 },
      { x: 8.233333333333333, y: 7.982 },
      { x: 9, y: 8.191 },
      { x: 9.6, y: 8.345 },
      { x: 10, y: 8.445 },
      { x: 11, y: 8.682 },
      { x: 11.433333333333334, y: 8.782 },
      { x: 12, y: 8.909 },
      { x: 13, y: 9.129 },
      { x: 14, y: 9.344 },
      { x: 15, y: 9.555 },
      { x: 16, y: 9.764 },
      { x: 17, y: 9.971 },
      { x: 18, y: 10.178 },
      { x: 19, y: 10.383 },
      { x: 20, y: 10.587 },
      { x: 21, y: 10.791 },
      { x: 22, y: 10.995 },
      { x: 23, y: 11.2 },
      { x: 24, y: 11.406 },
      { x: 25, y: 11.611 },
      { x: 26, y: 11.817 },
      { x: 27, y: 12.022 },
      { x: 28, y: 12.224 },
      { x: 29, y: 12.424 },
      { x: 30, y: 12.62 },
      { x: 31, y: 12.814 },
      { x: 32, y: 13.005 },
      { x: 33, y: 13.193 },
      { x: 34, y: 13.381 },
      { x: 35, y: 13.567 },
      { x: 36, y: 13.753 },
      { x: 37, y: 13.938 },
    ],
  },
  {
    id: 'curva-w-f-4',
    data: [
      { x: 0, y: 3.729 },
      { x: 1, y: 4.802 },
      { x: 2, y: 5.837 },
      { x: 3, y: 6.629 },
      { x: 3.3333333333333335, y: 6.858 },
      { x: 4, y: 7.271 },
      { x: 5, y: 7.802 },
      { x: 6, y: 8.25 },
      { x: 7, y: 8.64 },
      { x: 8, y: 8.989 },
      { x: 8.233333333333333, y: 9.066 },
      { x: 9, y: 9.306 },
      { x: 9.6, y: 9.483 },
      { x: 10, y: 9.598 },
      { x: 11, y: 9.872 },
      { x: 11.433333333333334, y: 9.986 },
      { x: 12, y: 10.133 },
      { x: 13, y: 10.386 },
      { x: 14, y: 10.633 },
      { x: 15, y: 10.875 },
      { x: 16, y: 11.114 },
      { x: 17, y: 11.352 },
      { x: 18, y: 11.588 },
      { x: 19, y: 11.822 },
      { x: 20, y: 12.057 },
      { x: 21, y: 12.291 },
      { x: 22, y: 12.526 },
      { x: 23, y: 12.762 },
      { x: 24, y: 12.999 },
      { x: 25, y: 13.238 },
      { x: 26, y: 13.476 },
      { x: 27, y: 13.714 },
      { x: 28, y: 13.95 },
      { x: 29, y: 14.184 },
      { x: 30, y: 14.415 },
      { x: 31, y: 14.643 },
      { x: 32, y: 14.869 },
      { x: 33, y: 15.094 },
      { x: 34, y: 15.317 },
      { x: 35, y: 15.54 },
      { x: 36, y: 15.764 },
      { x: 37, y: 15.988 },
    ],
  },
  {
    id: 'curva-w-f-5',
    data: [
      { x: 0, y: 4.166 },
      { x: 1, y: 5.371 },
      { x: 2, y: 6.502 },
      { x: 3, y: 7.369 },
      { x: 3.3333333333333335, y: 7.62 },
      { x: 4, y: 8.075 },
      { x: 5, y: 8.661 },
      { x: 6, y: 9.158 },
      { x: 7, y: 9.594 },
      { x: 8, y: 9.986 },
      { x: 8.233333333333333, y: 10.073 },
      { x: 9, y: 10.344 },
      { x: 9.6, y: 10.545 },
      { x: 10, y: 10.675 },
      { x: 11, y: 10.986 },
      { x: 11.433333333333334, y: 11.116 },
      { x: 12, y: 11.282 },
      { x: 13, y: 11.569 },
      { x: 14, y: 11.848 },
      { x: 15, y: 12.121 },
      { x: 16, y: 12.39 },
      { x: 17, y: 12.657 },
      { x: 18, y: 12.923 },
      { x: 19, y: 13.187 },
      { x: 20, y: 13.451 },
      { x: 21, y: 13.715 },
      { x: 22, y: 13.981 },
      { x: 23, y: 14.248 },
      { x: 24, y: 14.517 },
      { x: 25, y: 14.787 },
      { x: 26, y: 15.058 },
      { x: 27, y: 15.329 },
      { x: 28, y: 15.599 },
      { x: 29, y: 15.866 },
      { x: 30, y: 16.132 },
      { x: 31, y: 16.395 },
      { x: 32, y: 16.656 },
      { x: 33, y: 16.916 },
      { x: 34, y: 17.176 },
      { x: 35, y: 17.436 },
      { x: 36, y: 17.698 },
      { x: 37, y: 17.962 },
    ],
  },
]

export const curvaPesoMData = [
  {
    id: 'curva-w-m-1',
    data: [
      { x: 0, y: 2.507 },
      { x: 1, y: 3.433 },
      { x: 2, y: 4.363 },
      { x: 3, y: 5.065 },
      { x: 4, y: 5.611 },
      { x: 5, y: 6.05 },
      { x: 6, y: 6.41 },
      { x: 7, y: 6.714 },
      { x: 8, y: 6.977 },
      { x: 9, y: 7.211 },
      { x: 10, y: 7.425 },
      { x: 11, y: 7.624 },
      { x: 12, y: 7.813 },
      { x: 13, y: 7.994 },
      { x: 14, y: 8.169 },
      { x: 15, y: 8.339 },
      { x: 16, y: 8.505 },
      { x: 17, y: 8.668 },
      { x: 18, y: 8.829 },
      { x: 19, y: 8.986 },
      { x: 20, y: 9.142 },
      { x: 21, y: 9.297 },
      { x: 22, y: 9.45 },
      { x: 23, y: 9.602 },
      { x: 24, y: 9.752 },
      { x: 25, y: 9.901 },
      { x: 25.633333333333333, y: 9.995 },
      { x: 26, y: 10.048 },
      { x: 27, y: 10.193 },
      { x: 28, y: 10.335 },
      { x: 29, y: 10.474 },
      { x: 30, y: 10.609 },
      { x: 31, y: 10.742 },
      { x: 32, y: 10.873 },
      { x: 33, y: 11.001 },
      { x: 34, y: 11.127 },
      { x: 35, y: 11.252 },
      { x: 36, y: 11.376 },
    ],
  },
  {
    id: 'curva-w-m-2',
    data: [
      { x: 0, y: 2.865 },
      { x: 1, y: 3.866 },
      { x: 2, y: 4.864 },
      { x: 3, y: 5.611 },
      { x: 4, y: 6.191 },
      { x: 5, y: 6.659 },
      { x: 6, y: 7.046 },
      { x: 7, y: 7.374 },
      { x: 8, y: 7.66 },
      { x: 9, y: 7.916 },
      { x: 10, y: 8.15 },
      { x: 11, y: 8.368 },
      { x: 12, y: 8.576 },
      { x: 13, y: 8.775 },
      { x: 14, y: 8.968 },
      { x: 15, y: 9.156 },
      { x: 16, y: 9.34 },
      { x: 17, y: 9.522 },
      { x: 18, y: 9.7 },
      { x: 19, y: 9.876 },
      { x: 20, y: 10.05 },
      { x: 21, y: 10.223 },
      { x: 22, y: 10.395 },
      { x: 23, y: 10.566 },
      { x: 24, y: 10.736 },
      { x: 25, y: 10.905 },
      { x: 25.633333333333333, y: 11.011 },
      { x: 26, y: 11.071 },
      { x: 27, y: 11.235 },
      { x: 28, y: 11.396 },
      { x: 29, y: 11.554 },
      { x: 30, y: 11.708 },
      { x: 31, y: 11.86 },
      { x: 32, y: 12.009 },
      { x: 33, y: 12.155 },
      { x: 34, y: 12.299 },
      { x: 35, y: 12.442 },
      { x: 36, y: 12.583 },
    ],
  },
  {
    id: 'curva-w-m-3',
    data: [
      { x: 0, y: 3.346 },
      { x: 1, y: 4.453 },
      { x: 2, y: 5.541 },
      { x: 3, y: 6.346 },
      { x: 4, y: 6.97 },
      { x: 5, y: 7.477 },
      { x: 6, y: 7.9 },
      { x: 7, y: 8.263 },
      { x: 8, y: 8.58 },
      { x: 9, y: 8.866 },
      { x: 10, y: 9.128 },
      { x: 11, y: 9.374 },
      { x: 12, y: 9.608 },
      { x: 13, y: 9.833 },
      { x: 14, y: 10.051 },
      { x: 15, y: 10.265 },
      { x: 16, y: 10.474 },
      { x: 17, y: 10.681 },
      { x: 18, y: 10.885 },
      { x: 19, y: 11.087 },
      { x: 20, y: 11.288 },
      { x: 21, y: 11.488 },
      { x: 22, y: 11.687 },
      { x: 23, y: 11.885 },
      { x: 24, y: 12.083 },
      { x: 25, y: 12.279 },
      { x: 25.633333333333333, y: 12.403 },
      { x: 26, y: 12.474 },
      { x: 27, y: 12.665 },
      { x: 28, y: 12.854 },
      { x: 29, y: 13.04 },
      { x: 30, y: 13.222 },
      { x: 31, y: 13.4 },
      { x: 32, y: 13.576 },
      { x: 33, y: 13.749 },
      { x: 34, y: 13.919 },
      { x: 35, y: 14.088 },
      { x: 36, y: 14.255 },
    ],
  },
  {
    id: 'curva-w-m-4',
    data: [
      { x: 0, y: 3.878 },
      { x: 1, y: 5.105 },
      { x: 2, y: 6.291 },
      { x: 3, y: 7.158 },
      { x: 4, y: 7.83 },
      { x: 5, y: 8.38 },
      { x: 6, y: 8.844 },
      { x: 7, y: 9.245 },
      { x: 8, y: 9.599 },
      { x: 9, y: 9.918 },
      { x: 10, y: 10.213 },
      { x: 11, y: 10.49 },
      { x: 12, y: 10.755 },
      { x: 13, y: 11.01 },
      { x: 14, y: 11.258 },
      { x: 15, y: 11.501 },
      { x: 16, y: 11.74 },
      { x: 17, y: 11.977 },
      { x: 18, y: 12.212 },
      { x: 19, y: 12.444 },
      { x: 20, y: 12.676 },
      { x: 21, y: 12.907 },
      { x: 22, y: 13.138 },
      { x: 23, y: 13.369 },
      { x: 24, y: 13.6 },
      { x: 25, y: 13.83 },
      { x: 25.633333333333333, y: 13.974 },
      { x: 26, y: 14.058 },
      { x: 27, y: 14.283 },
      { x: 28, y: 14.506 },
      { x: 29, y: 14.724 },
      { x: 30, y: 14.939 },
      { x: 31, y: 15.151 },
      { x: 32, y: 15.359 },
      { x: 33, y: 15.564 },
      { x: 34, y: 15.767 },
      { x: 35, y: 15.967 },
      { x: 36, y: 16.167 },
    ],
  },
  {
    id: 'curva-w-m-5',
    data: [
      { x: 0, y: 4.35 },
      { x: 1, y: 5.69 },
      { x: 2, y: 6.961 },
      { x: 3, y: 7.882 },
      { x: 4, y: 8.596 },
      { x: 5, y: 9.184 },
      { x: 6, y: 9.684 },
      { x: 7, y: 10.12 },
      { x: 8, y: 10.507 },
      { x: 9, y: 10.858 },
      { x: 10, y: 11.183 },
      { x: 11, y: 11.49 },
      { x: 12, y: 11.783 },
      { x: 13, y: 12.066 },
      { x: 14, y: 12.342 },
      { x: 15, y: 12.612 },
      { x: 16, y: 12.88 },
      { x: 17, y: 13.145 },
      { x: 18, y: 13.408 },
      { x: 19, y: 13.669 },
      { x: 20, y: 13.931 },
      { x: 21, y: 14.192 },
      { x: 22, y: 14.454 },
      { x: 23, y: 14.716 },
      { x: 24, y: 14.978 },
      { x: 25, y: 15.24 },
      { x: 25.633333333333333, y: 15.405 },
      { x: 26, y: 15.5 },
      { x: 27, y: 15.758 },
      { x: 28, y: 16.012 },
      { x: 29, y: 16.263 },
      { x: 30, y: 16.51 },
      { x: 31, y: 16.753 },
      { x: 32, y: 16.993 },
      { x: 33, y: 17.229 },
      { x: 34, y: 17.463 },
      { x: 35, y: 17.695 },
      { x: 36, y: 17.926 },
    ],
  },
]

export const curvaLunghezzaFParams = {
  yMin: 20,
  yMax: 110,
  xMin: 0,
  xMax: 36,
  data: curvaLunghezzaFData,
  yTickValues: [20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
  xTickValues: [0, 6, 12, 18, 24, 30, 36],
}

export const curvaLunghezzaMParams = {
  yMin: 20,
  yMax: 110,
  xMin: 0,
  xMax: 36,
  data: curvaLunghezzaMData,
  yTickValues: [20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
  xTickValues: [0, 6, 12, 18, 24, 30, 36],
}

export const curvaPesoFParams = {
  yMin: 0,
  yMax: 20,
  xMin: 0,
  xMax: 36,
  data: curvaPesoFData,
  yTickValues: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20],
  xTickValues: [0, 6, 12, 18, 24, 30, 36],
}

export const curvaPesoMParams = {
  yMin: 0,
  yMax: 20,
  xMin: 0,
  xMax: 36,
  data: curvaPesoMData,
  yTickValues: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20],
  xTickValues: [0, 6, 12, 18, 24, 30, 36],
}
