import React from 'react'
import useTrans from 'hooks/useTrans'
import { FormCheckbox, FormField, OptinLabel } from 'components/atoms'
import { StepProps } from '../FormRegistrazione.interfaces'

const CondizioniPrivacy = ({ formik }: StepProps) => {
  const t = useTrans()

  return (
    <>
      <p className="condizioni-privacy__title">
        {t(
          'Ai sensi e per gli effetti degli articoli artt. 7,13 e 15 del Regolamento UE 2016/679,'
        )}
      </p>
      <FormField
        status={
          formik.touched.privacy_informativa
            ? formik.errors.privacy_informativa
              ? 'error'
              : 'success'
            : null
        }
        errorMessage={formik.errors.privacy_informativa}
        layout="full"
      >
        <FormCheckbox
          id="privacy_informativa"
          checked={formik.values?.privacy_informativa}
          onChange={formik.handleChange('privacy_informativa')}
          label={<OptinLabel type="informativa" />}
        />
      </FormField>
      <FormField
        status={
          formik.touched.privacy_commerciale
            ? formik.errors.privacy_commerciale
              ? 'error'
              : 'success'
            : null
        }
        errorMessage={formik.errors.privacy_commerciale}
        layout="full"
      >
        <FormCheckbox
          id="privacy_commerciale"
          checked={formik.values?.privacy_commerciale}
          onChange={formik.handleChange('privacy_commerciale')}
          label={<OptinLabel type="commerciale" />}
          accordion
        />
      </FormField>
      <FormField
        status={
          formik.touched.privacy_profilazione
            ? formik.errors.privacy_profilazione
              ? 'error'
              : 'success'
            : null
        }
        errorMessage={formik.errors.privacy_profilazione}
        layout="full"
      >
        <FormCheckbox
          id="privacy_profilazione"
          checked={formik.values?.privacy_profilazione}
          onChange={formik.handleChange('privacy_profilazione')}
          label={<OptinLabel type="profilazione" />}
        />
      </FormField>

      <FormField
        status={
          formik.touched.datiParticolari
            ? formik.errors.datiParticolari
              ? 'error'
              : 'success'
            : null
        }
        errorMessage={formik.errors.datiParticolari}
        layout="full"
      >
        <FormCheckbox
          id="datiParticolari"
          checked={formik.values?.datiParticolari}
          onChange={formik.handleChange('datiParticolari')}
          label={<OptinLabel type="datiParticolari" />}
        />
      </FormField>
    </>
  )
}

export default CondizioniPrivacy
