import { Formik } from 'formik'
import React, { useState } from 'react'
import * as yup from 'yup'
import { Button, FormField, FormInput, FormLayout } from 'components/atoms'
import styles from './FormRecuperaPassword.module.sass'
import { useRecuperaPasswordMutation } from '@gql/graphql'
import { useRouter } from 'next/router'

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Questo campo è obbligatorio')
    .email("Il formato dell'email dev'essere valido")
    .nullable(),
})

const FormRecuperaPassword = ({ iframe, from }: { iframe?: boolean; from?: string }) => {
  const router = useRouter()
  const [Submitted, setSubmitted] = useState(false)
  const initialValues = {
    email: null,
  }

  const [recuperaPassword, { loading }] = useRecuperaPasswordMutation()

  return (
    <Formik
      isInitialValid={false}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values) =>
        recuperaPassword({
          variables: {
            email: values.email,
          },
        })
          .then((res) => {
            iframe ?
                setSubmitted(true)
            :
                router.push(`/recupera-password/thank-you/?status=${res?.data?.recuperaPassword?.content?.status_code}`)
          })
          .catch((error) => console.log(error))
      }
    >
      {(props) =>       
        iframe && Submitted ? (
        <>
        <div className={styles.thankyouMessage}>
          <p className={styles.thankyouMessageText}>E-Mail di recupero password inviata correttamente</p>
          <Button
            href={`/login-parto-riparto?f=${encodeURIComponent(from)}`}
            label="Torna al login"
            variant="primary"
          />
          </div>
        </>
      ) : (
        <>
          <FormLayout>
            <FormField
              label="Email*"
              status={props.touched.email ? (props.errors.email ? 'error' : 'success') : null}
              errorMessage={props.errors.email as string}
              layout="full"
            >
              <FormInput
                value={props.values.email}
                onBlur={props.handleBlur('email')}
                onChange={props.handleChange('email')}
                placeholder="Inserisci la tua email"
              />
            </FormField>
          </FormLayout>
          <Button
            disabled={!!props.errors.email || !props.dirty}
            variant="primary"
            label="Invia"
            onClick={() => props.handleSubmit()}
            type="submit"
            className={styles.submit}
            loading={loading}
          />
        </>
      )}
    </Formik>
  )
}

export default FormRecuperaPassword
