import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFade, Navigation } from 'swiper'
import { useTrans } from 'hooks'
import { MenuItemType } from 'utils/types'
import { Button, Tooltip, WContainer, WImage } from '@components/atoms'
import styles from './LandingProdottoIngredienti.module.sass'
import { ArrowLeft, ArrowRight } from '@components/atoms/Icons'
import useGTM from '../../../hooks/useGTM';

SwiperCore.use([EffectFade, Navigation])

interface Props {
  categoria: any
  item: MenuItemType
  variant?: '' | 'latti' | 'bio'
  className?: string
  paginaTitolo?: string
}

const LandingProdottoIngredienti = (props: Props) => {

  const { 
    categoria = undefined,
    item = undefined,
    variant = '',
    className = '',
    paginaTitolo = '',
  } = props

  const t = useTrans()
  const [activeSlide, setActiveSlide] = useState(0)
  const { trackingGAevent } = useGTM()

  return item ? (
    <div className={`${styles.landingProdottoIngredienti} ${variant ? styles[`landingProdottoIngredienti--${variant}`] : ''} ${className}`}>
      {!!categoria && !!categoria.video && (
        <div className={styles.bg}>
          <video autoPlay muted loop className={styles.bgVideo} playsInline={true}>
            <source src={categoria.video} type="video/mp4" />
          </video>
          {!!item.image && (
            <div className={styles.bgImageBox}>
              <WImage
                src={item.image}
                maxWidth={1920}
                maxHeight={700}
                layout="fill"
                objectFit='cover'
              />
            </div>
          )}
        </div>
      )}
      <WContainer>
        <div className={styles.layout}>
          <div className={styles.content}>
            <p className={styles.intro}>{t("Come li facciamo")}</p>
            {!!item.name && <p className={styles.title}>{item.name}</p>}
            {!!item.description && <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.description }} />}
          </div>
          {item.children?.length > 0 && (
            <div className={styles.list}>
              <div className={styles.pages} id="swiper-pagination"></div>
              <div className={styles.activeIconMobile}><img src={item.children[activeSlide % item.children.length].image || 'https://via.placeholder.com/96'} /></div>
              <div className={styles.navigation}>
                <button id="swiper-prev" className={`${styles.navigationButton} ${styles['navigationButton--prev']}`} type="button">
                  <ArrowLeft />
                </button>
                <button id="swiper-next" className={`${styles.navigationButton} ${styles['navigationButton--next']}`} type="button">
                  <ArrowRight />
                </button>
              </div>
              <Swiper 
                spaceBetween={20}
                slidesPerView={1} 
                watchOverflow
                onSlideChange={(swiper) => {
                  setActiveSlide(swiper.activeIndex)
                  trackingGAevent('16', paginaTitolo, 'ingredienti', 'ingredienti')
                }}
                loop
                pagination={{ 
                  el: '#swiper-pagination',
                  clickable: true,
                  renderBullet: (index, className) => (
                    '<span class="' + styles.pageButton + ' ' + className + '"><img src="' + (item.children[index % item.children.length].image || 'https://via.placeholder.com/96') + '" /></span>'
                  )
                }}
                navigation={{
                  prevEl: '#swiper-prev',
                  nextEl: '#swiper-next',
                }}
                effect="fade"
                breakpoints={{
                  992: {
                    allowTouchMove: false,
                  }
                }}
              >
                {item.children.map(x => (
                  <SwiperSlide key={x.pk}>
                    <div className={styles.item}>
                      {!!x.name && <p className={styles.itemTitle} dangerouslySetInnerHTML={{ __html: x.name }} />}
                      {!!x.description && <div className={styles.itemDescription} dangerouslySetInnerHTML={{ __html: x.description }} />}
                      {x.children?.length > 0 && (
                        <div className={styles.itemButtons}>
                          {x.children.map(y => (
                            <Tooltip key={`${x.pk}_${y.pk}`} pos="bottom" id={`ingredienti_tooltip_${x.pk}_${y.pk}`} label={y.description} className={styles.itemTooltip}>
                              {/* Ci vuole lo span intorno altrimenti il Tooltip non funziona */}
                              <span><Button label={y.name} variant="ghost" /></span>
                            </Tooltip>
                          ))}
                        </div>
                      )}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
      </WContainer>
    </div>
  ) : <></>
}

export default LandingProdottoIngredienti
