import React from 'react'
import { ProdottoType } from 'utils/types'
import { useTrans } from 'hooks'
import { Button, WImage } from '@components/atoms'
import styles from './ProdottoCorrelatoCard.module.sass'
import useGTM from '../../../hooks/useGTM';
import { useRouter } from 'next/router'


interface Props {
  prodotto: ProdottoType
  className?: string
}

const ProdottoCorrelatoCard = (props: Props) => {

  const { 
    prodotto = undefined,
    className = '',
  } = props

  const t = useTrans()
  const { trackingGAevent } = useGTM()
  const router = useRouter()

  return prodotto ? (
    <div className={`${styles.prodottoCorrelatoCard} ${className}`}>
      <div className={styles.imageBox}>
        <WImage
          src={prodotto.mainImage}
          maxWidth={164}
          maxHeight={164}
          layout="intrinsic"
          objectFit="cover"
        />
      </div>
      <div className={styles.content}>
        <p className={styles.title}>{prodotto.titolo}</p>
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: prodotto.descrizione }}
        />
        {!!prodotto.link && (
          <Button
            label={t('Acquista ora')}
            color="white"
            className={styles.cta}
            href={`${prodotto.link}?utm_source=mellin.it&utm_medium=referral&utm_campaign=consigliati-["${router.asPath}"]`}
            onClick={() => trackingGAevent('9', 'shop', prodotto.titolo, window.location.href)}
            newPage={prodotto.blank}
            external={prodotto.blank}
          />
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ProdottoCorrelatoCard
