import React from 'react'
import useTrans from 'hooks/useTrans'
import styles from './HeroSlider.module.sass'
import { MenuItemType } from 'utils/types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { WImage, Button } from 'components/atoms'
import { useRouter } from 'next/router'
import useGTM from '../../../hooks/useGTM'

interface Props {
  item: MenuItemType
  preloadImage?: boolean
  className?: string
  page?: string
}

const HeroSlider = (props: Props) => {
  const { className = '', item = undefined, preloadImage = false, page = '' } = props
  const t = useTrans()
  const { trackingGAevent } = useGTM()
  const router = useRouter()

  const handleTracking = (label: string) => {
    page === 'homepage' ? 
    trackingGAevent('13', 'homepage', 'hero_slider', label) :
      page === 'prodotti' &&
        trackingGAevent('16', 'prodotti', 'banner', label) 
    }

  return !!item ? (
    <div className={`${styles.heroSlider} ${className}`}>
      <Swiper
        spaceBetween={16}
        slidesPerView={1}
        pagination={{ clickable: true }}
        watchOverflow
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          576: {
            slidesPerView: 1,
          },
          991: {
            slidesPerView: 1,
          },
        }}
      >
        {item.children?.length > 0 &&
          item.children.map((x) => (
            <SwiperSlide key={x.pk}>
              <div className={styles.layout}>
                <div className={styles.imageBox}>
                  <WImage
                    src={x.image}
                    maxWidth={374}
                    maxHeight={244}
                    layout="intrinsic"
                    priority={preloadImage}
                    loading={preloadImage ? 'eager' : 'lazy'}
                    alt={x.name}
                    title={x.name}
                  />
                </div>
                <div className={styles.content}>
                  {!!x.name && <p className={styles.title}>{x.name}</p>}
                  {!!x.description && (
                    <div
                      className={styles.description}
                      dangerouslySetInnerHTML={{ __html: x.description }}
                    />
                  )}
                  {!!x.url && (
                    <div className={styles.ctaBox}>
                      <Button
                        label={x.ctaLabel ? x.ctaLabel : t('Scoprili ora')}
                        size="sm"
                        className={styles.cta}
                        href={`${x.url}?utm_source=mellin.it&utm_medium=referral&utm_campaign=banner-["${router.pathname}"]`}
                        onClick={() => handleTracking(x.ctaLabel ?? t('Scoprili ora'))}
                        newPage
                      />
                    </div>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  ) : (
    <></>
  )
}
export default HeroSlider
