import React, { useState, DetailedHTMLProps, InputHTMLAttributes } from 'react'
import { useTrans } from 'hooks'
import { Button, FormError } from 'components/atoms'
import {
  CheckboxUnmarked,
  CheckboxMarked,
  RadioUnmarked,
  RadioMarked,
} from 'components/atoms/Icons'
import styles from './FormCheckbox.module.sass'

interface Props {
  id: string
  label?: string | React.ReactNode
  name?: string
  value?:
    | (DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & string)
    | number
    | readonly string[]
  className?: string
  color?: '' | 'white'
  size?: 'sm' | 'md'
  checked?: boolean
  radioStyle?: boolean
  accordion?: boolean
  readOnly?: boolean
  disabled?: boolean
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

const FormCheckbox = (props: Props) => {
  const {
    id = '',
    label = '',
    name = '',
    value = '',
    className = '',
    color = '',
    size = 'md',
    checked = false,
    radioStyle = false,
    accordion = false,
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
  } = props

  const t = useTrans()
  const [accordionOpen, setAccordionOpen] = useState(false)

  const classNames = `
    ${styles.formCheckbox}
    ${disabled ? styles['formCheckbox--disabled'] : ''}
    ${status ? styles[`formCheckbox--${status}`] : ''}
    ${color ? styles[`formCheckbox--${color}`] : ''}
    ${size ? styles[`formCheckbox--${size}`] : ''}
    ${accordion ? styles['formCheckbox--accordion'] : ''}
    ${accordionOpen ? styles['formCheckbox--accordionOpen'] : ''}
    ${className ? className : ''}`

  return (
    <div className={classNames}>
      <label htmlFor={id} className={styles.inputWrap}>
        <input
          checked={checked}
          name={name}
          value={value}
          className={styles.input}
          type="checkbox"
          readOnly={readOnly}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          id={id}
        />
        <span className={styles.styledInput}>
          {radioStyle ? (
            checked ? (
              <RadioMarked />
            ) : (
              <RadioUnmarked />
            )
          ) : checked ? (
            <CheckboxMarked />
          ) : (
            <CheckboxUnmarked />
          )}
        </span>
        <span className={styles.content}>
          <span className={styles.label}>{label}</span>
          {accordion && (
            <Button
              label={accordionOpen ? t('Mostra meno') : t('Leggi tutto')}
              variant="ghost"
              size="sm"
              className={styles.accordionTrigger}
              onClick={() => setAccordionOpen(!accordionOpen)}
            />
          )}
        </span>
      </label>
      <FormError message={errorMessage} visible={status === 'error'} />
    </div>
  )
}

export default FormCheckbox
