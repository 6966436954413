import React from 'react'
import { SeparatorSettings } from './SeparatorSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import { useEditor } from '@craftjs/core'

export const defaultProps = {
  link: '#',
  pageLink: '',
}

export const Separator = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    };

    const { 
      link,
      pageLink,
    } = blockProps

    const { children } = props
    
    const { enabled } = useEditor((state) => ({
      enabled: state.options.enabled,
    }));

    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={'block-separator ' + props.className}
      ></div>
    );
  },
  defaultProps
);

Separator.craft = withCraft({
  name: 'Separator',
  defaultProps: defaultProps,
  settings: SeparatorSettings,
});
