import { Formik } from 'formik'
import React, { useRef, useState } from 'react'
import * as yup from 'yup'
import useTrans from '@hooks/useTrans'
import ReCAPTCHA from 'react-google-recaptcha'
import { useCreateRichiestaContattoMutation } from 'gql/graphql'
import { Feedback } from 'components/atoms/Icons'
import styles from './FormContattaci.module.sass'
import {
  Button,
  FormCheckbox,
  FormField,
  FormInput,
  FormLayout,
  FormLegend,
  FormSelect,
  FormTextarea,
  Modal,
  OptinLabel,
} from 'components/atoms'
import useGTM from '../../../hooks/useGTM';

const validationSchema = (t) =>
  yup.object().shape({
    nome: yup.string().nullable().required(t('Questo campo non può essere vuoto')),
    cognome: yup.string().nullable().required(t('Questo campo non può essere vuoto')),
    email: yup
      .string()
      .email(t("Il formato dell'email dev'essere valido"))
      .nullable()
      .required(t('Questo campo non può essere vuoto')),
    argomento: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    domanda: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    telefono: yup.number().nullable(),
    privacyCareline: yup
      .boolean()
      .required(t('Questo campo non può essere vuoto'))
      .oneOf([true], t('Devi accettare i Termini e le Condizioni')),
  })
  interface Props {
    site: string
  }

const FormContattaci = ( props : Props) => {
  const { site = '' } = props
  const t = useTrans()
  const [open, setOpen] = useState(false)
  const [createRichiesta, { loading }] = useCreateRichiestaContattoMutation()
  const recaptchaRef = useRef<ReCAPTCHA>()
  const { trackingGAevent } = useGTM()
  return (
    <>
    <Formik
      validationSchema={validationSchema(t)}
      isInitialValid={false}
      initialValues={{
        nome: null,
        cognome: null,
        email: null,
        telefono: null,
        argomento: null,
        domanda: null,
        privacyCareline: false,
        site: site
    
      }}
      onSubmit={async (values, { resetForm }) => {
        const token = await recaptchaRef.current.executeAsync()

        if (token) {
          createRichiesta({
            variables: {
              input: {
                ...values,
                captcha: token,
              },
            },
          })
            .then((res) => {
              trackingGAevent('11', 'contact', values.argomento, 'success')
              res.data?.createRichiestaContatto?.status && (setOpen(true), resetForm())
            })
            .catch((err) => {
              trackingGAevent('11', 'contact', values.argomento, 'error')
              console.log(err)
            })
        } else {
          console.log(values)
        }

        recaptchaRef.current.reset()
      }}
    >
      {(props) => (
         <>
        <FormLayout>
          <FormField
            label={t('Nome*')}
            status={props.touched.nome ? (props.errors.nome ? 'error' : 'success') : null}
            errorMessage={props?.errors?.nome}
          >
            <FormInput
              onBlur={props.handleBlur('nome')}
              placeholder={t('Nome')}
              value={props.values.nome || ''}
              onChange={props.handleChange('nome')}
            />
          </FormField>
          <FormField
            label={t('Cognome*')}
            status={props.touched.cognome ? (props.errors.cognome ? 'error' : 'success') : null}
            errorMessage={props?.errors?.cognome}
          >
            <FormInput
              onBlur={props.handleBlur('cognome')}
              placeholder={t('Cognome')}
              value={props.values.cognome}
              onChange={props.handleChange('cognome')}
            />
          </FormField>
          <FormField
            label="Email*"
            status={props.touched.email ? (props.errors.email ? 'error' : 'success') : null}
            errorMessage={props?.errors?.email}
          >
            <FormInput
              onBlur={props.handleBlur('email')}
              placeholder="Email"
              value={props.values.email || ''}
              onChange={props.handleChange('email')}
            />
          </FormField>
          <FormField
            label={t('Telefono')}
            status={props.touched.telefono ? (props.errors.telefono ? 'error' : 'success') : null}
            errorMessage={props?.errors?.telefono}
          >
            <FormInput
              onBlur={props.handleBlur('telefono')}
              placeholder={t('Telefono')}
              value={props.values.telefono || ''}
              onChange={props.handleChange('telefono')}
            />
          </FormField>
          <FormField
            label={t('Argomento della tua richiesta*')}
            layout="full"
            status={props.touched.argomento ? (props.errors.argomento ? 'error' : 'success') : null}
            errorMessage={props?.errors?.argomento}
          >
            <FormSelect
              options={[
                { value: 'informazioni-prodotto-latte', label: 'Informazioni prodotto latte' },
                {
                  value: 'informazioni-prodotto-svezzamento',
                  label: 'Informazioni prodotto svezzamento',
                },
                { value: 'reperibilita-prodotto', label: 'Reperibilità prodotto' },
                { value: 'commerciale', label: 'Contatto commerciale' },
                // { value: 'pediatra', label: 'Il pediatra risponde' },
                { value: 'assistenza-tecnica', label: 'Assistenza tecnica sito-app' },
                { value: 'segnalazioni-prodotto', label: 'Segnalazione prodotto' },
                { value: 'altro', label: 'Altro' },
              ]}
              onBlur={props.handleBlur('argomento')}
              placeholder={t('Seleziona Argomento')}
              value={props.values.argomento || ''}
              onChange={(value) => props.setFieldValue('argomento', value?.value)}
            />
          </FormField>

          <FormField
            label={t('La tua richiesta*')}
            layout="full"
            status={props.touched.domanda ? (props.errors.domanda ? 'error' : 'success') : null}
            errorMessage={props?.errors?.domanda}
          >
            <FormTextarea
              onBlur={props.handleBlur('domanda')}
              placeholder={t('Scrivi la tua domanda')}
              value={props.values.domanda || ''}
              onChange={props.handleChange('domanda')}
            />
          </FormField>
          <FormField
            status={
              props.touched.privacyCareline
                ? props.errors.privacyCareline
                  ? 'error'
                  : 'success'
                : null
            }
            errorMessage={props.errors.privacyCareline}
            layout="full"
          >
            <FormCheckbox
              id="privacyCareline"
              checked={props.values?.privacyCareline}
              onChange={props.handleChange('privacyCareline')}
              label={<OptinLabel type="informativa_careline" />}
            />
          </FormField>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
          />
          </FormLayout>
          <FormLegend label={t('* Campi obbligatori')} />
          <div className={styles.submitBox}>
            <Button
              loading={loading}
              disabled={!props.isValid}
              onClick={() => props.handleSubmit()}
              variant="primary"
              label={t('Invia la richiesta')}
            />
          </div>
          </>
      
        
      )}
    </Formik>
        <Modal open={open} setOpen={setOpen}>
        <Modal.Content
          icon={<Feedback />}
          title={t('Grazie per aver contattato il team Mellin')}
          description={t(
            'Abbiamo ricevuto correttamente la tua domanda e ti risponderemo il prima possibile. Troverai nella tua casella di posta una mail di conferma con il riepilogo dei tuoi dati.'
          )}
        />
        <Modal.Buttons>
          <Button variant="ghost" label={t('Torna alla home')} href={'/'} />
        </Modal.Buttons>
      </Modal>
       </>
  )
}

export default FormContattaci
