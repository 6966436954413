import React from 'react'
import { BoxList as OriginalBoxList } from '@components/organisms'
import { BoxListSettings } from './BoxListSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import { useEditor } from '@craftjs/core'

export const defaultProps = {}

export const BoxList = withBlock(
  (props) => {
   
    const { children } = props
    
    const { enabled } = useEditor((state) => ({
      enabled: state.options.enabled,
    }));
    
    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={'block-box-list ' + (children?.props?.children ? '' : 'block-box-list--empty ') + props.className}
      >
        {enabled ? (
          children?.props?.children
        ) : (
          <OriginalBoxList>
            {children?.props?.children}
          </OriginalBoxList>
        )}
      </div>
    );
  },
  defaultProps
);

BoxList.craft = withCraft({
  name: 'BoxList',
  defaultProps: defaultProps,
  settings: BoxListSettings,
});
