import React from 'react'
import useTrans from 'hooks/useTrans'
import { Edit } from 'components/atoms/Icons'
import { Button } from 'components/atoms'
import { Props as ButtonProps } from 'components/atoms/Button/Button'
import styles from './FormCard.module.sass'

interface ContentProps {
  children: any
}

interface ButtonsProps {
  children: any
}

interface Props {
  title?: string
  description?: string
  className?: string
  editMode?: boolean
  editButton?: ButtonProps
  editLabel: string
  children?: any
}

const FormCardContent = (props: ContentProps) => {

  const { 
    children = null,
  } = props

  return (
    <div className={styles.content}>
      {children}
    </div>
  )
}

const FormCardButtons = (props: ButtonsProps) => {

  const { 
    children = null,
  } = props

  return (
    <div className={styles.buttons}>
      {children}
    </div>
  )
}

const FormCard = (props: Props) => {

  const { 
    title = '',
    description = '',
    className = '',
    editMode = false,
    editButton = null,
    editLabel = '',
    children = null,
  } = props

  const t = useTrans()

  return (
    <div className={`${styles.formCard} ${editMode ? styles["formCard--edit-mode"] : ''} ${className}`}>
      <div className={styles.head}>
        <p className={styles.title}>{title}</p>
        {!editMode && editButton && <Button {...{ 
            variant: 'ghost',
            label: `${editLabel ? editLabel : t('Modifica')}`,
            icon: <Edit />,
            iconPos: 'right',
            size: 'sm',
            ...editButton,
            className: `${styles.editButton} ${editButton.className ? ` ${editButton.className}` : ''}`
          }} 
        />}
      </div>
      {description && <div className={styles.description}>{description}</div>}
      {children}
    </div>
  )
}

FormCard.Content = FormCardContent
FormCard.Buttons = FormCardButtons

export default FormCard
