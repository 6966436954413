import React from 'react'
import { useEditor } from '@craftjs/core'
import { QuoteBox as OriginalQuoteBox } from 'components/molecules'
import { QuoteBoxFloatingSettings, QuoteBoxSettings } from './QuoteBoxSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

export const defaultProps = {
  src: 'https://via.placeholder.com/287x215',
  alt: '',
  image: '',
  showImage: true
}

export const QuoteBox = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    };

    const { 
      showImage,
      src,
      image,
      alt,
    } = blockProps;

    const { children } = props

    const { enabled } = useEditor((state) => ({
      enabled: state.options.enabled,
    }));

    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={'block-quote-box ' + props.className}
      >
        <OriginalQuoteBox 
          titolo={children?.props?.children[0]}
          descrizione={children?.props?.children[1]}
          showImage={showImage}
          image={image ? image : src}
          alt={alt}
          editMode={enabled}
        />
      </div>
    );
  },
  defaultProps
);

QuoteBox.craft = withCraft({
  name: 'QuoteBox',
  defaultProps: defaultProps,
  settings: QuoteBoxSettings,
  floatingSettings: QuoteBoxFloatingSettings,
});
