import React, { useState, useEffect } from 'react'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { defaultProps } from './index'
import { adminApiAxiosInstance } from '../../services/api'

export const ActivityCardSettings = (props) => {
  const [urlOpts, setUrlOpts] = useState()

  useEffect(() => {
    adminApiAxiosInstance.get('linkable_objects/').then((res) => {
      setUrlOpts(
        res.data.reduce(
          (r, x) =>
            r.concat(
              x.linkable_objects.map((y) => ({
                label: y.alias,
                value: x.content_type.id + '|' + y.id,
              }))
            ),
          []
        )
      )
    })
  }, [])

  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="link"
          type="text"
          label="URL"
          defaultValue={defaultProps['link']}
        />
        {urlOpts && (
          <ToolbarItem
            propKey="block"
            propSubKey="pageLink"
            type="select"
            label="Page Link"
            options={urlOpts}
            nullable
            defaultValue={defaultProps['pageLink']}
          />
        )}
      </ToolbarSection>
    </>
  )
}
