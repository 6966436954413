import { Formik } from 'formik'
import React, { useState } from 'react'
import useTrans from 'hooks/useTrans'
import * as yup from 'yup'
import { useUpdatePasswordMutation } from 'gql/graphql'
import {
  Button,
  FormField,
  FormInput,
  FormInputPassword,
  FormLayout,
} from 'components/atoms'
import { FormCard } from 'components/molecules'

const validationSchema = (t) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t("Il formato dell'email dev'essere valido"))
      .nullable()
      .required(t('Questo campo non può essere vuoto')),
    vecchia_password: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    nuova_password: yup
      .string()
      .nullable()
      .required(t('Questo campo non può essere vuoto'))
      .min(8, t('Minimo 8 Caratteri'))
      .matches('^(?=.*?[A-Z])', t('La password deve contenere almeno una lettare maiuscola')),
  })

const FormAccountPrivacy = ({ initialValuesFromProps, onCancel }: any) => {
  const [updatePassword, { loading }] = useUpdatePasswordMutation()
  const [error, setError] = useState()
  const t = useTrans()
  return (
    <Formik
      isInitialValid={false}
      validationSchema={validationSchema(t)}
      onSubmit={(values) =>
        updatePassword({
          variables: {
            input: {
              password: values?.vecchia_password,
              newPassword: values?.nuova_password
            },
          },
        })
          .then((res) =>
            res?.data?.updatePassword?.message === '200'
              ? onCancel()
              : setError(res?.data?.updatePassword?.message)
          )
          .catch((err) => console.log(err))
      }
      initialValues={{
        email: initialValuesFromProps?.email || null,
        vecchia_password: initialValuesFromProps?.vecchia_password || null,
        nuova_password: initialValuesFromProps?.nuova_password || null,
      }}
    >
      {(props) => (
        <FormCard title={t('Modifica i tuoi dati account')} editMode>
          <FormCard.Content>
            <FormLayout>
              <FormField
                label={t('Email')}
                status={props.touched?.email ? (props.errors?.email ? 'error' : 'success') : null}
                errorMessage={props?.errors?.email}
              >
                <FormInput
                  disabled={true}
                  onBlur={props.handleBlur('email')}
                  placeholder={t('Email')}
                  value={props.values?.email}
                  onChange={props.handleChange('email')}
                />
              </FormField>
              <FormField
                label={t('Vecchia Password')}
                status={
                  props.touched?.vecchia_password
                    ? props.errors?.vecchia_password
                      ? 'error'
                      : 'success'
                    : null
                }
                errorMessage={props?.errors?.vecchia_password}
                helper={{
                  id: 'password-helper',
                  label: (
                    <>
                      La password deve contenere:
                      <br /> - minimo 8 caratteri
                      <br /> - almeno una lettera maiuscola
                    </>
                  ),
                  pos: 'right',
                }}
              >
                <FormInputPassword
                  togglePassword
                  onBlur={props.handleBlur('vecchia_password')}
                  placeholder={t('Inserisci vecchia password')}
                  value={props.values?.vecchia_password}
                  onChange={props.handleChange('vecchia_password')}
                />
              </FormField>
              <FormField
                label={t('Nuova Password')}
                status={
                  props.touched?.nuova_password
                    ? props.errors?.nuova_password
                      ? 'error'
                      : 'success'
                    : null
                }
                errorMessage={props?.errors?.nuova_password}
                helper={{
                  id: 'password-helper',
                  label: (
                    <>
                      La password deve contenere:
                      <br /> - minimo 8 caratteri
                      <br /> - almeno una lettera maiuscola
                    </>
                  ),
                  pos: 'right',
                }}
              >
                <FormInputPassword
                  togglePassword
                  onBlur={props.handleBlur('nuova_password')}
                  placeholder={t('Inserisci Nuova Password*')}
                  value={props.values?.nuova_password}
                  onChange={props.handleChange('nuova_password')}
                />
              </FormField>
            </FormLayout>
            <p>{error}</p>
          </FormCard.Content>

          <FormCard.Buttons>
            <Button label={t('Annulla')} onClick={onCancel} variant="ghost" />
            <Button
              type="submit"
              disabled={!props.isValid}
              onClick={() => props.handleSubmit()}
              label={t('Salva modifiche')}
              variant="primary"
            />
          </FormCard.Buttons>
        </FormCard>
      )}
    </Formik>
  )
}

export default FormAccountPrivacy
