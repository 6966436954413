import React from 'react'
import useTrans from 'hooks/useTrans'
import { MyAccountViewItem } from 'components/molecules'
import styles from './MyAccountAccountView.module.sass'

interface Props {
  email?: string
}

const MyAccountAccountView = (props: Props) => {

  const { 
    email = ''
  } = props

  const t = useTrans()

  return (
    <div className={styles.myAccountAccountView}>
      <MyAccountViewItem label={t("Email")} value={email} />
      <MyAccountViewItem label={t("Password")} value="••••••••••" />
    </div>
  )
}

export default MyAccountAccountView
