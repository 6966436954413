import React, { useEffect, useState } from 'react'
import { useUtente } from 'hooks'
import { PageBuilder } from 'react-pagebuilder/pagebuilder/PageBuilder'

interface Props {
  contentTypeId: number
  objectId: number
  pkey: string
  serverSideContent?: any
  size?: '' | 'xs'
}

const PlaceholderReact = (props: Props) => {
  const { contentTypeId = 0, objectId = 0, pkey = '', serverSideContent = null, size = '' } = props

  const { utente, loading } = useUtente()
  const [content, setContent] = useState<any>(null)

  useEffect(() => {
    let content = null
    if(loading) {
      content = null
    } else if (utente && utente.edit) {
      content = (
        <div className={`pb-content active ${size ? `pb-content--${size}` : ''}`}>
          <iframe
            className="pagebuilder-placeholder"
            src={`/pagebuilder/?contentTypeId=${contentTypeId}&objectId=${objectId}&key=${pkey}`}
            title="Pagebuilder"
          />
        </div>
      )
    } else {
      content = (
        <div className={`pb-content ${size ? `pb-content--${size}` : ''}`}>
          <PageBuilder
            contentTypeId={contentTypeId}
            objectId={objectId}
            pkey={pkey}
            serverSideContent={serverSideContent}
          />
        </div>
      )
    }
    setContent(content)
  }, [utente, loading])

  return <>{!!content && content}</>
}

export default PlaceholderReact
