import React from 'react'
import { getYearOptions } from 'utils/safe'
import { FormSelect } from 'components/atoms'
import { Props as FormSelectProps } from 'components/atoms/FormSelect/FormSelect'
import styles from './FormDate.module.sass'

interface Props {
  placeholder?: string
  name?: string
  value: string
  disabled?: boolean
  status?: '' | 'success' | 'error'
  onChange: (value: string) => void
  dayProps?: FormSelectProps
  monthProps?: FormSelectProps
  yearProps?: FormSelectProps
  yearFrom?: number
  yearNumber?: number
}

const FormDate = (props: Props) => {
  const {
    placeholder = 'GG/MM/YYYY',
    name = '',
    value = null,
    disabled = false,
    status = '',
    onChange = () => {},
    dayProps = {
      options: [...Array.from({ length: 31 }).keys()].map((i) => ({
        label: (i + 1).toString(),
        value: (i + 1).toString().padStart(2, '0'),
      })),
    },
    monthProps = {
      options: [...Array.from({ length: 12 }).keys()].map((i) => ({
        label: (i + 1).toString(),
        value: (i + 1).toString().padStart(2, '0'),
      })),
    },
    yearProps = null,
    yearFrom = 1900,
    yearNumber = new Date().getFullYear() + 1 - 1900,
  } = props

  const [year, month, day] = value ? value.split('-') : [null, null, null]

  const onDayChange = (v) => {
    onChange(`${year || '0000'}-${month || '00'}-${v?.value || '00'}`)
  }
  const onMonthChange = (v) => {
    onChange(`${year || '0000'}-${v?.value || '00'}-${day || '00'}`)
  }
  const onYearChange = (v) => {
    onChange(`${v?.value || '0000'}-${month || '00'}-${day || '00'}`)
  }

  return (
    <div className={styles.formDate}>
      <FormSelect
        placeholder={placeholder.split('/')[0]}
        name={name ? `${name}_day` : 'date_day'}
        value={day}
        disabled={disabled}
        status={status}
        onChange={(v) => onDayChange(v)}
        isSearchable
        {...dayProps}
      />
      <FormSelect
        placeholder={placeholder.split('/')[1]}
        name={name ? `${name}_month` : 'date_month'}
        value={month}
        disabled={disabled}
        status={status}
        onChange={(v) => onMonthChange(v)}
        isSearchable
        {...monthProps}
      />
      <FormSelect
        placeholder={placeholder.split('/')[2]}
        name={name ? `${name}_year` : 'date_year'}
        value={year}
        disabled={disabled}
        status={status}
        onChange={(v) => onYearChange(v)}
        isSearchable
        {...{
          options: getYearOptions(yearFrom, yearNumber).reverse(),
          ...yearProps,
        }}
      />
    </div>
  )
}

export default FormDate
