import React from 'react'
import { ActivityCard as OriginalActivityCard } from '@components/molecules'
import { ActivityCardSettings } from './ActivityCardSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import { useEditor } from '@craftjs/core'

export const defaultProps = {
  link: '#',
  pageLink: '',
}

export const ActivityCard = withBlock(
  (props) => {

    const blockProps = {
      ...defaultProps,
      ...props.block,
    };
    
    const { 
      link,
      pageLink,
    } = blockProps

    const { children } = props
    
    const { enabled } = useEditor((state) => ({
      enabled: state.options.enabled,
    }));

    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={'block-activity-card ' + props.className}
      >
        <OriginalActivityCard
          item={{
            name: children.props.children[0],
            description: children.props.children[1],
            ctaLabel: children.props.children[2],
            url: pageLink || link || null,
          }} 
          editMode={enabled} 
        />
      </div>
    );
  },
  defaultProps
);

ActivityCard.craft = withCraft({
  name: 'ActivityCard',
  defaultProps: defaultProps,
  settings: ActivityCardSettings,
});
