import React from 'react'
import { Check } from 'components/atoms/Icons'
import styles from './StepBar.module.sass'

interface ItemProps {
  index: number
  step: string
  status: 'past' | 'active' | 'future'
}

interface Props {
  steps: string[]
  activeStepIndex: number
  className: string
}

const StepBarItem = (props: ItemProps) => {
  const { index = 1, step = '', status = 'future' } = props

  return (
    <span className={`${styles.item} ${styles[`item--${status}`]}`}>
      <span className={styles.dot}>{status === 'past' ? <Check /> : index}</span>
      <span className={styles.label}>{step}</span>
    </span>
  )
}

const StepBar = (props: Props) => {
  const { steps = [], activeStepIndex = 1, className = '' } = props

  return (
    <div className={`${styles.stepBar} ${className}`}>
      {steps?.length &&
        steps.map((step, index) => (
          <StepBarItem
            key={index}
            index={index + 1}
            step={step}
            status={
              index + 1 === activeStepIndex ? 'active' : index < activeStepIndex ? 'past' : 'future'
            }
          />
        ))}
    </div>
  )
}

StepBar.Item = StepBarItem

export default StepBar
