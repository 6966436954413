import Ciuccio from "@components/atoms/Icons/Ciuccio"
import CiuccioX from "@components/atoms/Icons/CiuccioX"
import { 
  BCorp,
  BiberonIllustration,
  Calendar,
  CalendarIllustration,
  Close, 
  CurveCrescitaIllustration, 
  Facebook, 
  Gravidanza,
  GravidanzaIllustration, 
  Instagram, 
  LogoNoBordo, 
  PartoRiparto, 
  ProdottiIllustration, 
  ScarpaIllustration, 
  SvezzamentoIllustration, 
  YouTube,
  SocietaIllustration,
  PianetaIllustration,
  BambiniIllustration,
  NewsletterIllustration,
  CustomareCareIllustration,
  CoccardaIllustration,
  ManagerDonnaIllustration,
  LaureaIllustration,
  ClessidraIllustration,
  WelfareIllustration,
  ParentalPolicyIllustration,
  PaternitaIllustration,
  OstetricaIllustration,
  SupportoPsicopedagogicoIllustration,
  DirittiIllustration,
  SupportoPsicologicoIllustration,
} from "components/atoms/Icons"

export const getIcon = (key: string|null|undefined) => {
  if (!key) return null
  switch (key){
    case "close": return <Close />
    case "facebook": return <Facebook />
    case "instagram": return <Instagram />
    case "youtube": return <YouTube />
    case "parto-riparto": return <PartoRiparto />
    case "logo-no-bordo": return <LogoNoBordo />
    case "b-corp": return <BCorp />
    case "gravidanza": return <Gravidanza />
    case "calendar": return <Calendar />
    case "calendar-illustration": return <CalendarIllustration />
    case "ciuccio-x": return <CiuccioX />
    case "curve-crescita-illustration": return <CurveCrescitaIllustration />
    case "gravidanza-illustration": return <GravidanzaIllustration />
    case "biberon-illustration": return <BiberonIllustration />
    case "svezzamento-illustration": return <SvezzamentoIllustration />
    case "scarpa-illustration": return <ScarpaIllustration />
    case "prodotti-illustration": return <ProdottiIllustration />
    case "societa-illustration": return <SocietaIllustration />
    case "pianeta-illustration": return <PianetaIllustration />
    case "bambini-illustration": return <BambiniIllustration />
    case "newsletter-illustration": return <NewsletterIllustration />
    case "customarecare-illustration": return <CustomareCareIllustration />
    case "coccarda-illustration": return <CoccardaIllustration />
    case "manager-donna-illustration": return <ManagerDonnaIllustration />
    case "laurea-illustration": return <LaureaIllustration />
    case "clessidra-illustration": return <ClessidraIllustration />
    case "welfare-illustration": return <WelfareIllustration />
    case "parental-policy-illustration": return <ParentalPolicyIllustration />
    case "paternita-illustration": return <PaternitaIllustration />
    case "ostetrica-illustration": return <OstetricaIllustration />
    case "supporto-psicopedagogico-illustration": return <SupportoPsicopedagogicoIllustration />
    case "diritti-illustration": return <DirittiIllustration />
    case "supporto-psicologico-illustration": return <SupportoPsicologicoIllustration />
  }
  return null
}