import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Edit({ className }: Props) {
  return (
    <IconBase name="edit" w={24} className={className}>
      <path d="M21.5193 7.51933L17 3L6.02447 13.9755L10.5438 18.4949L21.5193 7.51933Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.02454 13.9755L4.41049 20.1089L10.5439 18.4949" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </IconBase>
  )
}

export default Edit
