import React from 'react'
import { FormError } from 'components/atoms'
import styles from './FormTextarea.module.sass'

interface Props {
  placeholder?: string
  name?: string
  value?: string
  className?: string
  disabled?: boolean
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const FormTextarea = (props: Props) => {
  const {
    placeholder = '',
    name = '',
    value = '',
    className = '',
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
  } = props

  const classNames = `
    ${styles.formTextarea} 
    ${status ? styles[`formTextarea--${status}`] : ''}
    ${className}`

  return (
    <div className={classNames}>
      <textarea
        name={name}
        value={value}
        className={styles.input}
        placeholder={placeholder}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
      />
      <FormError
        className={styles.error}
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormTextarea
