import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useUpdateServiceUserMutation, useMinisitoQuery } from 'gql/graphql'
import useTrans from 'hooks/useTrans'
import { useRouter } from 'next/router'
import { Button, FormField, FormCheckbox, FormLayout, OptinLabel } from 'components/atoms'
import { FormCard } from 'components/molecules'
import useLocalStorage from "@hooks/useLocalStorage";

const validationSchema = (t) =>
  yup.object().shape({
    privacyCommerciale: yup.boolean(),
    privacyProfilazione: yup.boolean(),
    datiParticolari: yup.boolean(),
  })

const FormPrivacy = ({ initialValuesFromProps, edit, setEdit }: any) => {
  const t = useTrans()
  const router = useRouter()
  const [updatePrivacy, { loading }] = useUpdateServiceUserMutation()
  const [token, _] = useLocalStorage('token', null)
  const { data } = useMinisitoQuery()
  const minisito = data?.minisito

  return (
    <Formik
      isInitialValid={false}
      validationSchema={validationSchema(t)}
      initialValues={{
        privacyInformativa: true,
        privacyCommerciale: initialValuesFromProps?.optinMarketing || false,
        privacyProfilazione: initialValuesFromProps?.optinProgramMarketing2 || false,
        datiParticolari: initialValuesFromProps?.optinProgramMarketing1 || false,
      }}
      enableReinitialize
    >
      {(props) => (
        <FormCard
          title={t('Modifica i tuoi dati account')}
          editLabel="Modifica preferenze"
          editMode={edit}
          editButton={{
            onClick: () => {
                  const url = token ? `${minisito.url}/preferenze/?jwt=${token}` : `${minisito.url}`
                  window.open(url, '_blank', 'noopener,noreferrer')
              }
          }}
        >
          <FormCard.Content>
            <FormLayout>
              <strong>
                {t(
                  'Ai sensi e per gli effetti degli articoli artt. 7,13 e 15 del Regolamento UE 2016/679,'
                )}
              </strong>

              <FormField
                layout="full"
                status={
                  props.touched?.privacyInformativa
                    ? props.errors?.privacyInformativa
                      ? 'error'
                      : 'success'
                    : null
                }
                errorMessage={props?.errors?.privacyInformativa}
              >
                <FormCheckbox
                  id="privacyInformativa"
                  onBlur={props.handleBlur('privacyInformativa')}
                  label={<OptinLabel type="informativa" />}
                  checked={props.values?.privacyInformativa}
                  onChange={(e) =>
                    edit && props.setFieldValue('privacyInformativa', e?.target?.checked)
                  }
                  readOnly={!edit}
                />
              </FormField>

              <FormField
                layout="full"
                status={
                  props.touched?.privacyCommerciale
                    ? props.errors?.privacyCommerciale
                      ? 'error'
                      : 'success'
                    : null
                }
                errorMessage={props?.errors?.privacyCommerciale}
              >
                <FormCheckbox
                  id="privacyCommerciale"
                  onBlur={props.handleBlur('privacyCommerciale')}
                  label={<OptinLabel type="commerciale" />}
                  checked={props.values?.privacyCommerciale}
                  onChange={(e) =>
                    edit && props.setFieldValue('privacyCommerciale', e?.target?.checked)
                  }
                  readOnly={!edit}
                  accordion
                />
              </FormField>
              <FormField
                layout="full"
                status={
                  props.touched?.privacyProfilazione
                    ? props.errors?.privacyProfilazione
                      ? 'error'
                      : 'success'
                    : null
                }
                errorMessage={props?.errors?.privacyProfilazione}
              >
                <FormCheckbox
                  id="privacyProfilazione"
                  onBlur={props.handleBlur('privacyProfilazione')}
                  label={<OptinLabel type="profilazione" />}
                  checked={props.values?.privacyProfilazione}
                  onChange={(e) =>
                    edit && props.setFieldValue('privacyProfilazione', e?.target?.checked)
                  }
                  readOnly={!edit}
                />
              </FormField>
              <FormField
                layout="full"
                status={
                  props.touched?.datiParticolari
                    ? props.errors?.datiParticolari
                      ? 'error'
                      : 'success'
                    : null
                }
                errorMessage={props?.errors?.datiParticolari}
              >
                <FormCheckbox
                  id="datiParticolari"
                  disabled={!!edit}
                  onBlur={props.handleBlur('datiParticolari')}
                  label={<OptinLabel type="datiParticolari" />}
                  checked={props.values?.datiParticolari}
                  readOnly
                />
              </FormField>
            </FormLayout>
          </FormCard.Content>
          {edit && (
            <FormCard.Buttons>
              <Button label={t('Annulla')} onClick={() => setEdit(false)} variant="ghost" />
              <Button
                loading={loading}
                type="submit"
                disabled={!props.isValid}
                onClick={() => props.handleSubmit()}
                label={t('Salva modifiche')}
                variant="primary"
              />
            </FormCard.Buttons>
          )}
        </FormCard>
      )}
    </Formik>
  )
}

export default FormPrivacy
