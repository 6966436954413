import React, { useEffect, useState } from 'react'
import useTrans from 'hooks/useTrans'
import { Button } from 'components/atoms'
import { MyAccountDatiView } from 'components/molecules'
import { ArrowRight } from 'components/atoms/Icons'
import { FormEditDatiPersonali } from 'components/organisms'
import styles from './DatiFormCard.module.sass'

interface Props {
  dati?: {
    nome?: string
    cognome?: string
    sesso?: 'f' | 'm' | 'x' | string | undefined | null
    dataNascita?: string
    mobile?: string
    isPregnant?: boolean
  }
}

//inserire SetInitialValuesFromProps per non fare refresh pagina
const DatiFormCard = (props: Props) => {
  const { dati = null } = props
  const t = useTrans()
  const [initialValuesFromProps, setInitialValuesFromProps] = useState({
    ...dati,
  })
  const [editDati, setEditDati] = useState(false)

  useEffect(() => {
    setInitialValuesFromProps({ ...dati })
  }, [dati])

  return (
    <div className={`${styles.datiFormCard} ${editDati ? styles['datiFormCard--edit'] : ''}`}>
      <div className={styles.content}>
        {editDati ? (
          <FormEditDatiPersonali
            initialValuesFromProps={{ ...initialValuesFromProps }}
            setInitialValuesFromProps={setInitialValuesFromProps}
            onCancel={() => setEditDati(false)}
          />
        ) : (
          <>
            <MyAccountDatiView {...initialValuesFromProps} />
            <Button
              label={t('Modifica i dati')}
              variant="ghost"
              size="sm"
              icon={<ArrowRight />}
              iconPos="right"
              onClick={() => setEditDati(true)}
              className={styles.editButton}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default DatiFormCard
