import React from 'react'

export const SimpleTextEditor = ({ value, setValue }) => {

  const onChange = (e) => {
    setValue(e.target.value)
  }

  return (
    <textarea
      className={`simple-text-editor`}
      value={value}
      onChange={onChange}
    />
  )
}
