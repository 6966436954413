import { Button, WImage } from '@components/atoms'
import useTrans from '@hooks/useTrans'
import React from 'react'
import { EventoType } from 'utils/types'
import styles from './EventoCard.module.sass'

interface Props {
  evento: EventoType
  preloadImage?: boolean
  className?: string
}

const EventoCard = (props: Props) => {
  const { evento = undefined, preloadImage = false, className = '' } = props

  const t = useTrans()
  

  return !!evento ? (
    <div className={`${styles.eventoCard} ${className}`}>
      <div className={styles.content}>
        {!!evento.titolo && <p className={styles.title}>{evento.titolo}</p>}
        {!!evento.abstract && <p className={styles.abstract}>{evento.abstract}</p>}
        {!!evento.url && <Button label={t('Leggi tutto')} variant="primary" href={evento.url} />}
      </div>
      <div className={styles.imageBox}>
          <WImage
            src={evento.previewImage}
            maxWidth={767}
            maxHeight={312}
            layout="intrinsic"
            objectFit="cover"
            quality ={100}
          />
        </div>
    </div>
  ) : (
    <></>
  )
}

export default EventoCard
