import React from 'react'
import useTrans from 'hooks/useTrans'
import { formatDate } from 'utils/safe'
import { MyAccountViewItem } from 'components/molecules'
import styles from './MyAccountDatiView.module.sass'

interface Props {
  nome?: string
  cognome?: string
  sesso?: 'm' | 'f' | 'x'
  dataNascita?: string
  mobile?: string
}

const MyAccountDatiView = ({ nome, cognome, sesso, dataNascita, mobile }: Props) => {
  const t = useTrans()

  return (
    <div className={styles.myAccountDatiView}>
      <MyAccountViewItem label={t('Nome')} value={nome} />
      <MyAccountViewItem label={t('Cognome')} value={cognome} />
      <MyAccountViewItem
        label={t('Sono')}
        value={sesso === 'F' ? t('Mamma') : sesso === 'M' ? t('Papà') : t('In dolce attesa')}
      />
      <MyAccountViewItem label={t('Data di nascita')} value={formatDate(dataNascita)} />
      <MyAccountViewItem label={t('Cellulare')} value={mobile} />
    </div>
  )
}

export default MyAccountDatiView
