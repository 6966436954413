import React, { useState, useEffect } from 'react'
import { useEditor } from '@craftjs/core'
import { getAncestors } from 'react-pagebuilder/utils/node'

const BreadcrumbStep = ({ nodeId, last }) => {
  const { query, actions, connectors } = useEditor()

  const [node, setNode] = useState()

  useEffect(() => {
    if (nodeId) {
      const node = query.node(nodeId).get()
      if (node) {
        setNode(node)
      }
    }
  }, [nodeId])

  const onSelectNode = () => {
    actions.selectNode(nodeId)
    document.getElementsByClassName(nodeId)[0].scrollIntoView({ behaviour: 'smooth' })
  }

  return (
    <>
      {node && (
        <div
          className="block-breadcrumbs-step"
          key={nodeId}
          onClick={() => onSelectNode()}
          ref={(ref) => connectors.hover(ref, nodeId)}
        >
          <span className="block-breadcrumbs-step__title">{node.data.name}</span>
          {!last && <span className="block-breadcrumbs-step__arrow"></span>}
        </div>
      )}
    </>
  )
}

export const Bottombar = () => {
  const { query, selectedNodeId, parent } = useEditor((state) => {
    const selected = [...state.events.selected]?.[0]
    return {
      selectedNodeId: selected,
      parent: state.nodes[selected]?.data.parent,
    }
  })

  const [hierarchy, setHierarchy] = useState()

  useEffect(() => {
    if (selectedNodeId) {
      const ancestors = getAncestors(selectedNodeId, query)
      const hierarchy = ancestors.reverse()
      hierarchy.push(selectedNodeId)
      setHierarchy(hierarchy)
    } else {
      setHierarchy([])
    }
  }, [selectedNodeId, parent]) // devo aggiornare anche quando cambia il parent

  return (
    <div className="bottombar">
      <div className="bottombar-main">
        {hierarchy &&
          (hierarchy.length === 0 ? (
            <div className="bottombar-placeholder">
              <p>No Component Selected</p>
            </div>
          ) : (
            <div className="block-breadcrumbs">
              {hierarchy.map((nodeId, index) => (
                <BreadcrumbStep key={index} nodeId={nodeId} last={index === hierarchy.length - 1} />
              ))}
            </div>
          ))}
      </div>
    </div>
  )
}
