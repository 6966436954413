import React from 'react'
import { useTrans } from 'hooks'
import { useMinisitoQuery } from 'gql/graphql'
import { Button } from 'components/atoms'
import styles from './MyAccountDeleteCard.module.sass'
import useLocalStorage from "@hooks/useLocalStorage";


const MyAccountDeleteCard = () => {
  const t = useTrans()
  const { data } = useMinisitoQuery()
  const [token, _] = useLocalStorage('token', null)
  const minisito = data?.minisito

  return (
    <div className={styles.myAccountDeleteCard}>
      <p className={styles.title}>{t('Cancella account')}</p>
      <p className={styles.description}>
        {t('Desidero che il mio profilo personale venga cancellato dal database di Mellin.')}
      </p>
      <Button
        label={t('Cancella account')}
        variant="ghost"
        size="sm"
        onClick={
          (event) => {
              const url = token ? `${minisito.url}?jwt=${token}` : `${minisito.url}`
              window.open(url, '_blank', 'noopener,noreferrer')
          }
        }
      />
    </div>
  )
}

export default MyAccountDeleteCard
