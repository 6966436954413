/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect } from 'react'
import useTrans from 'hooks/useTrans'
import { useRouter } from 'next/router'
import {
  Button,
  FormField,
  FormInput,
  FormInputPassword,
  FormLayout,
  FormLegend,
  FormStep,
} from 'components/atoms'
import { StepProps } from '../FormRegistrazione.interfaces'

const DatiAccesso = ({ setSteps, formik, from }: StepProps & { from?: string }) => {
  const t = useTrans()
  const router = useRouter()
  const { social, email, nome, cognome } = router.query
  useEffect(() => {
    if (social !== undefined) {
      nome && formik.setFieldValue('dati_accesso.nome', decodeURIComponent(nome?.toString()))
      email && formik.setFieldValue('dati_accesso.email', decodeURIComponent(email?.toString()))
      cognome &&
        formik.setFieldValue('dati_accesso.cognome', decodeURIComponent(cognome?.toString()))
    }
  }, [nome, cognome, email, social])

  return (
    <FormStep title={t('I tuoi dati di accesso')}>
      <FormStep.Content>
        <FormLayout>
          <FormField
            label={t('Nome*')}
            status={
              formik.touched.dati_accesso?.nome
                ? formik.errors.dati_accesso?.nome
                  ? 'error'
                  : 'success'
                : null
            }
            errorMessage={formik?.errors?.dati_accesso?.nome}
          >
            <FormInput
              readOnly={!!nome}
              onBlur={formik.handleBlur('dati_accesso.nome')}
              placeholder={t('Nome')}
              value={formik.values?.dati_accesso?.nome}
              onChange={formik.handleChange('dati_accesso.nome')}
            />
          </FormField>
          <FormField
            label={t('Cognome*')}
            status={
              formik.touched.dati_accesso?.cognome
                ? formik.errors.dati_accesso?.cognome
                  ? 'error'
                  : 'success'
                : null
            }
            errorMessage={formik?.errors?.dati_accesso?.cognome}
          >
            <FormInput
              readOnly={!!cognome}
              onBlur={formik.handleBlur('dati_accesso.cognome')}
              placeholder={t('Cognome')}
              value={formik.values.dati_accesso?.cognome}
              onChange={formik.handleChange('dati_accesso.cognome')}
            />
          </FormField>
          <FormField
            label={t('Email*')}
            status={
              formik.touched.dati_accesso?.email
                ? formik.errors.dati_accesso?.email
                  ? 'error'
                  : 'success'
                : null
            }
            errorMessage={formik?.errors?.dati_accesso?.email}
          >
            <FormInput
              readOnly={!!social}
              onBlur={formik.handleBlur('dati_accesso.email')}
              placeholder={t('Email')}
              value={formik.values.dati_accesso?.email}
              onChange={formik.handleChange('dati_accesso.email')}
            />
          </FormField>
          <FormField
            label={t('Password')}
            status={
              formik.touched.dati_accesso?.password
                ? formik.errors.dati_accesso?.password
                  ? 'error'
                  : 'success'
                : null
            }
            errorMessage={formik?.errors?.dati_accesso?.password}
            helper={{
              id: 'password-helper',
              label: (
                <>
                  La password deve contenere:
                  <br /> - minimo 8 caratteri
                  <br /> - almeno una lettera maiuscola
                </>
              ),
              pos: 'right',
            }}
          >
            <FormInputPassword
              togglePassword
              onBlur={formik.handleBlur('dati_accesso.password')}
              placeholder={t('Password')}
              value={formik.values?.dati_accesso.password}
              onChange={formik.handleChange('dati_accesso.password')}
            />
          </FormField>

          <FormField
            label={t('Conferma Password')}
            status={
              formik.touched.dati_accesso?.confirm_password
                ? formik.errors.dati_accesso?.confirm_password
                  ? 'error'
                  : 'success'
                : null
            }
            errorMessage={formik?.errors?.dati_accesso?.confirm_password}
            helper={{
              id: 'confirm-password-helper',
              label: (
                <>
                  Password e Conferma Password devono coincidere
                </>
              ),
              pos: 'right',
            }}
          >
            <FormInputPassword
              togglePassword
              onBlur={formik.handleBlur('dati_accesso.confirm_password')}
              placeholder={t('Conferma Password')}
              value={formik.values?.dati_accesso.confirm_password}
              onChange={formik.handleChange('dati_accesso.confirm_password')}
            />
          </FormField>

        </FormLayout>
        <FormLegend label={t('* Campi obbligatori')} />
      </FormStep.Content>
      <FormStep.Buttons>
        <Button
          label={t('Avanti')}
          disabled={!!formik.errors?.dati_accesso || !formik.touched.dati_accesso}
          variant="primary"
          onClick={() => {
            formik?.values?.dati_accesso?.password
              ? setSteps((prev) => prev + 1)
              : from === 'ariele'
              ? (window.location.href = `https://ariele.psicosocioanalisi.it/public/?nome=${formik?.values?.dati_accesso?.nome}&cognome=${formik?.values?.dati_accesso?.cognome}&email=${formik?.values?.dati_accesso?.email}`)
              : (window.location.href = `https://www.patronato.acli.it/consulenza-mellin/?cognome=${formik?.values?.dati_accesso?.cognome}&nome=${formik?.values?.dati_accesso?.nome}&email=${formik?.values?.dati_accesso?.email}&prres=CITTA`)
          }}
        />
      </FormStep.Buttons>
    </FormStep>
  )
}

export default DatiAccesso
