import React, { useEffect, useState } from 'react'
import { useNode } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { adminApiAxiosInstance } from '../../services/api'

import { defaultProps, dynamicProps } from './index'
import { getAllPaths, getDynamicValue, setDynamicValue } from '../../utils/dynamic'

const variantOpts = [
  { label: 'Primary', value: 'primary' },
  { label: 'Secondary', value: 'secondary' },
  { label: 'Ghost', value: 'ghost' },
]

const sizeOptions = [
  { label: 'Small', value: 'sm' },
  { label: 'Medium', value: 'md' },
]

export const ButtonSettings = (props) => {
  const { customs } = useNode((node) => ({
    customs: node.data.custom,
  }))

  const [urlOpts, setUrlOpts] = useState()

  useEffect(() => {
    adminApiAxiosInstance.get('linkable_objects/').then((res) => {
      setUrlOpts(
        res.data.reduce(
          (r, x) =>
            r.concat(
              x.linkable_objects.map((y) => ({
                label: y.alias,
                value: x.content_type.id + '|' + y.id,
              }))
            ),
          []
        )
      )
    })
  }, [])

  return (
    <>
      <ToolbarSection title="Style" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="variant"
          type="select"
          label="Variante"
          options={variantOpts}
          dynamic={dynamicProps.includes('variant') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['variant']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="size"
          type="select"
          label="Size"
          options={sizeOptions}
          dynamic={dynamicProps.includes('size') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['size']}
        />
      </ToolbarSection>
      {!props.floating && (
        <ToolbarSection title="Settings" initOpen>
          <ToolbarItem
            propKey="block"
            propSubKey="url"
            type="text"
            label="URL"
            dynamic={dynamicProps.includes('url') && customs.dynamicData}
            dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
            defaultValue={defaultProps['url']}
          />
          {urlOpts && (
            <ToolbarItem
              propKey="block"
              propSubKey="url"
              type="select"
              label="Page Link"
              options={urlOpts}
              nullable
              defaultValue={defaultProps['url']}
            />
          )}
          <ToolbarItem
            propKey="block"
            propSubKey="openNewTab"
            type="switch"
            label="Open in new tab"
            dynamic={dynamicProps.includes('openNewTab') && customs.dynamicData}
            dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
            defaultValue={defaultProps['openNewTab']}
          />
        </ToolbarSection>
      )}
    </>
  )
}

export const ButtonFloatingSettings = () => React.createElement(ButtonSettings, { floating: true })
