import React from 'react'
import useTrans from 'hooks/useTrans'
import styles from './HeroWithBox.module.sass'
import { MenuItemType } from 'utils/types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { WImage, Button } from 'components/atoms'
import { ProdottoCard } from '@components/molecules'
import { useRouter } from 'next/router'
import useWindowDimensions from '@hooks/useWindowDimensions'
import useGTM from '../../../hooks/useGTM';

interface Props {
  item: MenuItemType
  preloadImage?: boolean
  className?: string
}

const HeroWithBox = (props: Props) => {
  const { className = '', item = undefined, preloadImage = false } = props
  const t = useTrans()
  const { width } = useWindowDimensions()
  const { trackingGAevent } = useGTM()
  const router = useRouter()

  return !!item ? (
    <div className={styles.heroWithBox}>
      <Swiper
        spaceBetween={16}
        slidesPerView={1}
        pagination={{ clickable: true }}
        watchOverflow
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          576: {
            slidesPerView: 1,
          },
          991: {
            slidesPerView: 1,
          },
        }}
      >
        {item.children?.length > 0 &&
          item.children.map((child) => (
            <SwiperSlide key={child.pk}>
              <div className={styles.imageBox}>
                <WImage 
                  src={child.imageMobile && width < 768 ? child.imageMobile : child.image}
                  maxWidth={1920}
                  maxHeight={670}
                  layout="intrinsic"
                  objectFit="cover"
                  priority={preloadImage}
                  loading={preloadImage ? 'eager' : 'lazy'}
                />
              </div>
              <div className={styles.content__container}>
                <div className={styles.content}>
                  {!!child.name && <p className={styles.title}>{child.name}</p>}
                  {!!child.description && (
                    <div
                      className={styles.description}
                      dangerouslySetInnerHTML={{ __html: child.description }}
                    />
                  )}
                  <div className={styles.heroWithBox__productContainer}>
                    {child.children?.length > 0 &&
                      child.children.map((child2) => (
                        <div className={styles.heroWithBox__productCard} key={child2.pk}>
                          <ProdottoCard
                            key={child2.pk}
                            prodotto={{
                              pk: child2.pk,
                              titolo: child2.name,
                              descrizione: child2.description,
                              mainImage: child2.image,
                              link: child2.url,
                              blank: child2.blank,
                            }}
                          />
                        </div>
                      ))}
                  </div>
                  {!!child.url && (
                    <div className={styles.ctaBox}>
                      <Button
                        label={child.ctaLabel ? child.ctaLabel : t('Vai allo shop online')}
                        size="sm"
                        className={styles.cta}
                        variant="secondary"
                        href={`${child.url}?utm_source=mellin.it&utm_medium=referral&utm_campaign=banner-["${router.pathname}"]`}
                        onClick={() => trackingGAevent('9', 'shop', '', router.pathname)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  ) : (
    <></>
  )
}
export default HeroWithBox
