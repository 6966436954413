import { ProdottoCard } from '@components/molecules'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { MenuItemType } from 'utils/types'
import styles from './CategorieProdottoCorrelate.module.sass'
import useGTM from '../../../hooks/useGTM'


interface Props {
  item: MenuItemType
  className?: string
  paginaTitolo?: string
}

const CategorieProdottoCorrelate = (props: Props) => {

  const { 
    item = undefined,
    className = '',
    paginaTitolo = '',
  } = props
  const { trackingGAevent } = useGTM()

  return item ? (
    <div className={`${styles.categorieProdottoCorrelate} ${className}`}>
      {!!item.name && <p className={styles.title}>{item.name}</p>}
      <div className={styles.list}>
        <Swiper
          spaceBetween={10}
          slidesPerView={2.2}
          pagination={{ clickable: true }}
          watchOverflow
          breakpoints={{
            576: {
              slidesPerView: 3.3
            },
            768: {
              slidesPerView: 4.3
            },
            992: {
              slidesPerView: 6,
            },
          }}
        >
          {item.children?.length > 0 &&
            item.children.map((x) => (
              <SwiperSlide key={x.pk}>
                <ProdottoCard
                  key={x.pk}
                  onClick={() => trackingGAevent('16', paginaTitolo, item.name, x.name)}
                  prodotto={{
                    pk: x.pk,
                    titolo: x.name,
                    descrizione: x.description,
                    mainImage: x.image,
                    link: x.url,
                    blank: x.blank
                  }}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  ) : <></>
}

export default CategorieProdottoCorrelate
