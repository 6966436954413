import {
  Button,
  FormField,
  FormInput,
  FormInputPassword,
  FormLayout,
  Modal,
} from 'components/atoms'
import { Formik } from 'formik'
import { useTrans } from 'hooks'
import React, { useState } from 'react'
import * as yup from 'yup'
import { useResetPasswordMutation } from 'gql/graphql'

const validationSchema = (t) =>
  yup.object().shape({
    password: yup
      .string()
      .nullable()
      .required(t('Questo campo non può essere vuoto'))
      .min(8, t('Minimo 8 Caratteri'))
      .matches('^(?=.*?[A-Z])', 'Questo campo deve contenere almeno una lettera maiuscola.'),
  })

const FormResetPassword = ({ token }: { token: string }) => {
  const t = useTrans()
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(null)
  const [resetPassword, { loading }] = useResetPasswordMutation()
  return (
    <Formik
      validateOnChange
      isInitialValid={false}
      validationSchema={validationSchema(t)}
      onSubmit={(values) =>
        resetPassword({
          variables: {
            token,
            password: values?.password,
          },
        })
          .then((res) =>
            res?.data?.resetPassword?.status
              ? setOpen(true)
              : setError(t('Il link per il cambio password non è più valido.'))
          )
          .catch((err) => setError(t('Il link per il cambio password non è più valido.')))
      }
      initialValues={{
        password: null,
      }}
    >
      {(props) => (
        <>
          <FormField
            label={t('Password')}
            status={props.touched?.password ? (props.errors?.password ? 'error' : 'success') : null}
            errorMessage={props?.errors?.password}
            helper={{
              id: 'password-helper',
              label: (
                <>
                  La password deve contenere:
                  <br /> - minimo 8 caratteri
                  <br /> - almeno una lettera maiuscola
                </>
              ),
              pos: 'right',
            }}
          >
            <FormInputPassword
              togglePassword
              onBlur={props.handleBlur('password')}
              placeholder={t('Password')}
              value={props.values?.password}
              onChange={props.handleChange('password')}
            />
          </FormField>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <FormLayout>
            <div style={{ marginTop: 25 }}>
              <Button
                loading={loading}
                disabled={!props.isValid}
                onClick={() => props.handleSubmit()}
                variant="primary"
                label={t('Cambia Password')}
              />
            </div>
          </FormLayout>
          <Modal open={open} setOpen={setOpen}>
            <Modal.Content title={t('Hai cambiato password con successo')}></Modal.Content>
            <Button label="Vai al login" variant="primary" href="/login" />
          </Modal>
        </>
      )}
    </Formik>
  )
}

export default FormResetPassword
