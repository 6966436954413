import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useGetFigliUtenteQuery } from 'gql/graphql'
import { isBorn } from 'utils/safe'
import { Ciuccio, Dashboard, Hourglass, Logout, Mail, Plus, Profile } from 'components/atoms/Icons'
import styles from './MyAccountMenu.module.sass'
import useTrans from '@hooks/useTrans'
import { Button } from '@components/atoms'
import useGTM from '../../../hooks/useGTM'

interface ItemProps {
  icon?: any
  iconRight?: any
  label?: string
  active?: boolean
}

interface SlideProps {
  icon?: any
  iconRight?: any
  label?: string
  link?: string
  active?: boolean
  onClick?: () => void
}

interface Props {
  setModalAggiungiFiglioOpen: (v: boolean) => void
  className?: string
  activeItemKey: string
}

const menuItemsFixed = [
  { key: 'dashboard', icon: <Dashboard />, label: 'La tua bacheca', link: '/dashboard' },
  {
    key: 'profile',
    icon: <Profile />,
    label: 'I tuoi dati',
    link: '/dashboard/informazioni-personali',
  },
  { key: 'privacy', icon: <Mail />, label: 'Account e privacy', link: '/dashboard/account' },
]

const MyAccountMenuItem = (props: ItemProps) => {
  const { icon = null, iconRight = null, label = '', active = false } = props

  return (
    <div
      className={`${styles.myAccountMenuItem} ${active ? styles[`myAccountMenuItem--active`] : ''}`}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      {label && <span className={styles.label}>{label}</span>}
      {iconRight && <span className={styles.iconRight}>{iconRight}</span>}
    </div>
  )
}

const MyAccountMenuItemSlide = ({ icon, iconRight, label, link, active, onClick }: SlideProps) =>
  active ? (
    <MyAccountMenuItem icon={icon} iconRight={iconRight} label={label} active />
  ) : (
    <Link legacyBehavior href={link}>
      <a className={styles.item} onClick={onClick}>
        <MyAccountMenuItem icon={icon} iconRight={iconRight} label={label} />
      </a>
    </Link>
  )

const MyAccountMenu = (props: Props) => {
  const { setModalAggiungiFiglioOpen = () => undefined, className = '', activeItemKey = '' } = props
  const [menuItems, setMenuItems] = useState(menuItemsFixed)
  const { data, loading, error } = useGetFigliUtenteQuery()
  const { trackingGAevent } = useGTM()

  const t = useTrans()

  useEffect(() => {
    data &&
      !loading &&
      setMenuItems(() => [
        ...menuItemsFixed,
        ...data.figliUtente.map((figlio) => ({
          label: figlio.firstName,
          key: String(figlio.pk),
          icon: <Ciuccio />,
          iconRight: isBorn(figlio.dataNascita) ? null : <Hourglass />,
          link: `/dashboard/figlio/${figlio.pk}`,
        })),
      ])
  }, [data, loading])

  return menuItemsFixed.length > 0 ? (
    <div className={`${styles.myAccountMenu} ${className}`}>
      <div className={styles.list}>
        <div className={styles.block}>
          <div className={styles.blockList}>
            {menuItemsFixed.map((item) => (
              <MyAccountMenuItemSlide
                key={item.key}
                {...item}
                active={item.key === activeItemKey}
              />
            ))}
          </div>
        </div>
        {data?.figliUtente && data.figliUtente.length > 0 && (
          <div className={styles.block}>
            <p className={styles.blockTitle}>{t('I tuoi figli')}</p>
            <div className={styles.blockList}>
              {data.figliUtente.map((figlio) => (
                <MyAccountMenuItemSlide
                  key={figlio.pk}
                  icon={<Ciuccio />}
                  label={figlio.firstName}
                  iconRight={isBorn(figlio.dataNascita) ? null : <Hourglass />}
                  link={`/dashboard/figlio/${figlio.pk}`}
                  active={`figlio-${figlio.pk}` === activeItemKey}
                />
              ))}
            </div>
          </div>
        )}
        <div className={styles.addChild}>
          <Button
            label={t('Aggiungi un figlio')}
            icon={<Plus />}
            variant="ghost"
            onClick={() => setModalAggiungiFiglioOpen(true)}
          />
        </div>
        <div className={styles.logout}>
          <MyAccountMenuItemSlide
            label={t('Esci')}
            icon={<Logout />}
            link="/logout"
            onClick={() => trackingGAevent('7', 'logout', 'click')}
          />
        </div>
      </div>
      <div className={styles.slider}>
        <Swiper slidesPerView="auto">
          {menuItemsFixed.map((item) => (
            <SwiperSlide key={`my-account-menu-item-slide__${item.key}`}>
              <MyAccountMenuItemSlide {...item} active={item.key === activeItemKey} />
            </SwiperSlide>
          ))}
          {data?.figliUtente &&
            data.figliUtente.length > 0 &&
            data.figliUtente.map((figlio) => (
              <SwiperSlide key={`my-account-menu-item-slide__figlio-${figlio.pk}`}>
                <MyAccountMenuItemSlide
                  icon={<Ciuccio />}
                  label={figlio.firstName}
                  iconRight={isBorn(figlio.dataNascita) ? null : <Hourglass />}
                  link={`/dashboard/figlio/${figlio.pk}`}
                  active={`figlio-${figlio.pk}` === activeItemKey}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default MyAccountMenu
