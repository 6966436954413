import React from 'react'

interface Props {
  type:
    | 'commerciale'
    | 'profilazione'
    | 'informativa'
    | 'datiParticolari'
    | 'informativa_evento'
    | 'informativa_careline'
}

const OptinLabel = (props: Props) => {
  const { type } = props

  const informativa = (
    <a href="/informativa.pdf">
      <u>informativa</u>
    </a>
  )

  const informativa_careline = (
    <a href="/informativa_Mellin.it_ServizioConsumatori.docx">
      <u>informativa sulla privacy</u>
    </a>
  )

  const informativa_evento = (
    <a href="/EDP_INFORMATIVA_Live_Shopping.docx">
      <u>informativa</u>
    </a>
  )

  switch (type) {
    case 'informativa':
      return (
        <>
          * Dichiaro di aver preso visione dell’{informativa} privacy di Danone S.p.A. e di Danone
          Nutricia S.p.A. Società Benefit
        </>
      )

    case 'informativa_evento':
      return (
        <>
          * Dichiaro di aver preso visione dell’{informativa_evento} privacy di Danone Nutricia
          S.p.A. Società Benefit
        </>
      )

    case 'informativa_careline':
      return (
        <>
          * Ai sensi e per gli effetti degli artt. 7,13 e 15 del Regolamento UE 2016/679 dichiaro di
          aver preso visione dell’{informativa_careline} di Danone Nutricia S.p.A. Società Benefit
          ed acconsento al trattamento dei dati personali, anche relativi alla stato di salute
          qualora vengano da me comunicati, per ricevere la risposta alla mia richiesta.
        </>
      )

    case 'commerciale':
      return (
        <>
          Ai sensi e per gli effetti degli articoli 7 e 13 del Regolamento UE 2016/679, acconsento
          al trattamento dei dati personali per le finalità di cui al punto 4) lettera b) dell'
          {informativa}: svolgere attività di informazione e promozione commerciale e/o contattarla
          per indagare la sua volontà a partecipare a ricerche di mercato e/o ad entrare a far parte
          delle communities Danone, a mezzo, a titolo esemplificativo e non esaustivo, e-mail,
          telefono, sms, mms, Whatsapp, posta ordinaria e newsletter
        </>
      )
    case 'profilazione':
      return (
        <>
          Ai sensi e per gli effetti degli articoli 7 e 13 del Regolamento UE 2016/679, acconsento
          al trattamento dei dati personali per lo svolgimento di attività di profilazione di cui al
          punto 4) lettera c) dell'{informativa}
        </>
      )
    case 'datiParticolari':
      return (
        <>
          Ai sensi e per gli effetti degli articoli 7, 9 e 13 del Regolamento UE 2016/679,
          acconsento al trattamento dei dati particolari per le finalità previste dall’{informativa}
        </>
      )
    default:
      return null
  }
}

export default OptinLabel
