import React from 'react'
import { useTrans } from 'hooks'
import { Button, Modal } from 'components/atoms'

interface Props {
  open: boolean
  setOpen: (e: boolean) => void
  title: string
  description: string
  onConfirm: () => void
}

const DeleteModal = ({ open, setOpen, title, description, onConfirm }: Props) => {
  const t = useTrans()
  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Content title={title} description={description} />

      <Modal.Buttons>
        <Button
          variant="primary"
          label={t('Elimina dati')}
          onClick={() => {
            onConfirm && onConfirm()
          }}
        />
        <Button variant="ghost" label={t('Annulla')} onClick={() => setOpen(false)} />
      </Modal.Buttons>
    </Modal>
  )
}

export default DeleteModal
