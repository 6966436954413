import React, { useContext } from 'react'
import { useEditor } from '@craftjs/core'
import { Topbar } from './Topbar'
import { Sidebar } from './Sidebar'
import { Bottombar } from './Bottombar'
import { GlobalContext } from 'react-pagebuilder/services/globalContext'
import { ContextMenu } from '../ContextMenu'
import { LeftSidebar } from './LeftSidebar'

export const Viewport = ({ children }) => {
  const { connectors, selectedNodeId, draggedNodeId } = useEditor((state) => ({
    selectedNodeId: [...state.events.selected]?.[0],
    draggedNodeId: [...state.events.dragged]?.[0],
  }))

  const { previewMode } = useContext(GlobalContext)

  return (
    <>
      <div className="viewport">
        <Topbar />
        <div className="content-wrapper">
          {!previewMode && <LeftSidebar />}
          <div className={`content ${previewMode ? 'content__preview' : ''}`}>
            <div
              className={`craftjs-renderer ${previewMode ? 'craftjs-renderer__preview' : ''}`}
              ref={(ref) => connectors.select(connectors.hover(ref, null), null)}
            >
              <div className="canvas">{children}</div>
              <ContextMenu />
            </div>
            {!previewMode && <Bottombar />}
          </div>
          {!previewMode && <Sidebar />}
        </div>
      </div>
    </>
  )
}
