import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function YouTube({ className }: Props) {
  return (
    <IconBase name="you-tube" w={24} className={className}>
      <mask id="youtube-mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="3" width="24" height="19">
      <path d="M0 3.0002H24V21.0002H0V3.0002Z" fill="currentColor"/>
      </mask>
      <g mask="url(#youtube-mask0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M9 16.0003V8.00029L17 11.9933L9 16.0003ZM19.615 3.18429C16.011 2.93829 7.984 2.93929 4.385 3.18429C0.488 3.45029 0.029 5.80429 0 12.0003C0.029 18.1853 0.484 20.5493 4.385 20.8153C7.985 21.0613 16.011 21.0623 19.615 20.8153C23.512 20.5503 23.971 18.1963 24 12.0003C23.971 5.81529 23.516 3.45129 19.615 3.18429Z" fill="currentColor"/>
      </g>
    </IconBase>
  )
}

export default YouTube
