import { Element } from '@craftjs/core'
import { 
  TextEditor, 
  Text, 
  Button, 
  Image,
  Video,
  ProdottoBox,
  QuoteBox,
  LinkBox,
  Separator,
  BoxList,
  ActivityCard,
} from 'react-pagebuilder/components'
import {
  Text as TextIcon,
  Button as ButtonIcon,
  Image as ImageIcon,
  Video as VideoIcon,
  Quote as QuoteIcon,
} from 'react-pagebuilder/pb-components/icons'


export const BOXLIST = (
  <Element is={BoxList} canvas></Element>
);

export const TEXT = (
  <Element is={Text}>
    <TextEditor block={{
      text: '<p>Hello world!</p>',
      className: "text__content"
    }} />
  </Element>
)

export const BUTTON = (
  <Element is={Button}>
    <TextEditor
      block={{
        text: 'Click me', 
        noHtml: true,
      }}
    />
  </Element>
);

export const IMAGE = (
  <Image block={{ src: 'https://via.placeholder.com/800x600' }} />
);

export const VIDEO = (
  <Video block={{ src: '' }} />
);

export const PRODOTTOBOX = (
  <ProdottoBox block={{}} />
);

export const QUOTEBOX = (
  <Element is={QuoteBox}>
    <TextEditor block={{
      text: 'Titolo',
      noHtml: true
    }} />
    <TextEditor block={{
      text: '<p>Lorem ipsum...</p>',
    }} />
  </Element>
);

export const LINKBOX = (
  <Element is={LinkBox}>
    <TextEditor block={{
      text: 'Titolo',
      noHtml: true
    }} />
    <TextEditor block={{
      text: '<p>Lorem ipsum...</p>',
    }} />
    <TextEditor block={{
      text: 'Cta',
      noHtml: true
    }} />
  </Element>
);

export const ACTIVITYCARD = (
  <Element is={ActivityCard}>
    <TextEditor block={{
      text: 'Attività #',
      noHtml: true
    }} />
    <TextEditor block={{
      text: '<p>Lorem ipsum...</p>',
    }} />
    <TextEditor block={{
      text: 'Leggi l\'articolo',
      noHtml: true
    }} />
  </Element>
);

export const SEPARATOR = (
  <Separator block={{}} />
)

// possibilità di inserire icona per il block
export const conf = [
  { 
    title: 'Layout',
    blocks: {
      separator: {
        name: 'Separator',
        block: SEPARATOR,
      },
      boxList: {
        name: 'Box List',
        block: BOXLIST
      }
    }
  },
  {
    title: 'Basic',
    blocks: {
      text: {
        name: 'Text',
        icon: <TextIcon />,
        block: TEXT,
      },
      button: {
        name: 'Button',
        icon: <ButtonIcon />,
        block: BUTTON,
      },
      image: {
        name: 'Image',
        icon: <ImageIcon />,
        block: IMAGE,
      },
      video: {
        name: 'Video',
        icon: <VideoIcon />,
        block: VIDEO,
      },
    },
  }, {
    title: 'Box',
    blocks: {
      prodottoBox: {
        name: 'Prodotto Box',
        block: PRODOTTOBOX
      },
      quoteBox: {
        name: 'Quote Box',
        icon: <QuoteIcon />,
        block: QUOTEBOX
      },
      linkBox: {
        name: 'Link Box',
        block: LINKBOX
      },
      activityCard: {
        name: 'Attività Box',
        block: ACTIVITYCARD
      },
    }
  },
]
