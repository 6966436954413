import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { useTrans } from 'hooks'
import { useSelector } from 'react-redux'
import {
  Logo,
  LogoNoBordo,
  ShoppingBag,
  Search,
  Close,
  Menu,
  Profile,
  PayoffDesktop,
  PayoffMobile,
} from 'components/atoms/Icons'
import { Button, WContainer } from 'components/atoms'
import { Nav, NavMobile, TopMenu, SearchBar, SearchResultsDropdown } from 'components/organisms'
import styles from './Header.module.sass'
import useGTM from '../../../hooks/useGTM'
import useLocalStorage from '@hooks/useLocalStorage'

interface Props {
  className?: string
}

const Header = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const [searchDesktopOpen, setSearchDesktopOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchActive, setSearchActive] = useState(false)
  const [navMobileOpen, setNavMobileOpen] = useState(false)

  const { menu } = useSelector((state) => state.menu)
  const navMenu = menu?.find((item) => item.key === 'main')
  const { trackingGAevent } = useGTM()

  useEffect(() => {
    if (navMobileOpen) document.body.classList.add('w-noscrollnav')
    else document.body.classList.remove('w-noscrollnav')
  }, [navMobileOpen])

  useEffect(() => {
    if (searchActive) document.body.classList.add('w-noscroll')
    else document.body.classList.remove('w-noscroll')
  }, [searchActive])

  useEffect(() => {
    if (!searchDesktopOpen) setSearchActive(false)
  }, [searchDesktopOpen])

  const [token] = useLocalStorage('token', null)
  const [jwt, setJWT] = useState(null)
  useEffect(() => {
    setJWT(token)
  }, [token])
  const mellinshop_url =
    'https://www.mymellinshop.it/?utm_source=mellin.it&utm_medium=referral&utm_campaign=header'

  return (
    <header className={`${styles.header} ${className}`}>
      <div className={styles.banner}>
        <span className={styles.bannerLabel}>{t('Vuoi acquistare i prodotti Mellin?')}</span>
        <Button
          label={t('MyMellinShop')}
          icon={<ShoppingBag />}
          iconPos="right"
          size="sm"
          color="white"
          variant="secondary"
          href={jwt ? `${mellinshop_url}&jwt=${jwt}` : mellinshop_url}
          newPage
          external
          onClick={() => trackingGAevent('9', 'shop', 'btn_header', window.location.href)}
        />
      </div>
      <div className={styles.top}>
        <WContainer>
          <TopMenu />
        </WContainer>
      </div>
      <div className={styles.main}>
        <WContainer>
          <div className={styles.mainContent}>
            <Link legacyBehavior href="/">
              <a className={styles.logoWrap}>
                <div className={styles.logo}>
                  <Logo className={styles.logoDesktop} />
                  <LogoNoBordo className={styles.logoMobile} />
                </div>
                <div className={styles.payoff}>
                  <PayoffDesktop className={styles.payoffIconDesktop} />
                  <PayoffMobile className={styles.payoffIconMobile} />
                </div>
              </a>
            </Link>
            <div className={styles.areaRiservataMobileButton}>
              <Button
                href="/dashboard"
                label={t('Accedi')}
                variant="secondary"
                size="sm"
                icon={<Profile />}
                iconPos="right"
              /> 
            </div>
            <button
              type="button"
              className={styles.navMobileButton}
              onClick={() => setNavMobileOpen(!navMobileOpen)}
            >
              {navMobileOpen ? <Close /> : <Menu />}
            </button>
            <div className={styles.nav}>
              <Nav menu={navMenu} />
            </div>
            <div className={styles.searchBarContainer}>
              <button
                type="button"
                className={`${styles.searchTrigger} ${
                  searchDesktopOpen ? styles['searchTrigger--desktop-open'] : ''
                }`}
                onClick={() => {
                  if (!searchDesktopOpen) setSearchActive(true)
                  setSearchDesktopOpen(!searchDesktopOpen)
                }}
              >
                {searchDesktopOpen ? <Close /> : <Search />}
              </button>
              <SearchBar
                desktopOpen={searchDesktopOpen}
                value={searchQuery}
                setValue={setSearchQuery}
                active={searchActive}
                setActive={setSearchActive}
                className={`${styles.searchBar} ${
                  searchDesktopOpen ? styles['searchBar--desktop-open'] : ''
                }`}
              />
            </div>
            <Button
              label={t('Visita MyMellinShop')}
              icon={<ShoppingBag />}
              iconPos="right"
              size="sm"
              className={styles.shopButton}
              href={jwt ? `${mellinshop_url}&jwt=${jwt}` : mellinshop_url}
              newPage
              external
              onClick={() => trackingGAevent('9', 'shop', 'btn_header', window.location.href)}
            />
          </div>
        </WContainer>
      </div>
      <SearchResultsDropdown query={searchQuery} open={searchActive} setOpen={setSearchActive} />
      <NavMobile
        className={`${styles.navMobile} ${navMobileOpen ? styles['navMobile--open'] : ''}`}
      />
    </header>
  )
}

export default Header
