import React, { ReactNode } from 'react'
import styles from './FormLayout.module.sass'

interface Props {
  children?: ReactNode
}

const FormLayout = ({ children }: Props) => <div className={styles.formLayout}>{children}</div>

export default FormLayout
