import React, { useState, useEffect } from 'react'
import { useNode, useEditor } from '@craftjs/core'
import { initializeApollo } from '@gql/client'
import { PRODOTTO_CONSIGLIATO } from 'utils/queries'
import { ProdottoBox as OriginalProdottoBox } from 'components/molecules'
import { ProdottoBoxSettings } from './ProdottoBoxSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import { Loader } from 'react-pagebuilder/pb-components/atoms'

export const defaultProps = {
  prodottoId: 0,
};

export const ProdottoBox = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    };
    const { prodottoId } = blockProps;

    const _ = props.renderValue;

    const [prodotto, setProdotto] = useState(null)
    const [prodottoLoading, setProdottoLoading] = useState(false)
    const [prodottoError, setProdottoError] = useState(false)

    const client = initializeApollo()

    async function loadProdotto () {
      setProdottoLoading(true)
      const { data, error } = await client.query({
        query: PRODOTTO_CONSIGLIATO,
        variables: { pk: prodottoId }
      })
      setProdotto(data?.prodottoConsigliato || null)
      setProdottoLoading(false)
      setProdottoError(!!error || !data?.prodottoConsigliato)
    }

    useEffect(() => {
      if (prodottoId) {
        loadProdotto()
      }
      else {
        setProdotto(null)
      }
    }, [prodottoId])

    const {
      id,
      connectors: { connect },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode((state) => ({
      selected: state.events.selected,
      dragged: state.events.dragged,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }));

    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={'block-prodotto-box ' + props.className}
      >
        {prodottoError ? (
          <div className="empty-prodotto">
            <div className="empty-prodotto__error">
              Errore durante il caricamento del prodotto
            </div>
          </div>
        ) : prodottoLoading ? (
          <div className="empty-prodotto">
            <div className="empty-prodotto__loader">
              <Loader />
            </div>
          </div>
        ) : (
          prodotto ? (
            <OriginalProdottoBox
              prodotto={prodotto}
            />
          ) : (
            <div className="empty-prodotto">
              <div className="empty-prodotto__info">
                Seleziona un prodotto dal pannello <b>Settings</b> a destra
              </div>
            </div>
          )
        )}
      </div>
    );
  },
  defaultProps
);

ProdottoBox.craft = withCraft({
  name: 'ProdottoBox',
  defaultProps: defaultProps,
  settings: ProdottoBoxSettings,
});
