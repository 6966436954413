import React, { useState, useEffect } from 'react'

import dynamic from 'next/dynamic'

const ReactQuill = dynamic(() => import('react-quill'), {
  ssr: false,
  loading: () => <p>Loading ...</p>,
})
import 'react-quill/dist/quill.snow.css'

const modules = {
  clipboard: {
    matchVisual: true,
  },
  toolbar: {
    container: [
      [{ header: [false, 2, 3, 4, 5] }],
      [{ align: [false, 'center', 'right', 'justify'] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ script: 'sub' }, { script: 'super' }],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
      [{ background: ['#ffff00', '#ff00ff', '#00ffff'] }],
    ],
  },
}

/* const modules = {
    clipboard: {
        matchVisual: false
    },
    toolbar: {
      container: "#toolbar",
    },
  }; */

const formats = [
  'header',
  'align',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'color',
  'code-block',
  'background',
]

const CustomToolbar = () => (
  <div id="toolbar">
    <span className="ql-formats">
      <select className="ql-header" defaultValue="">
        <option value="1">Titolo</option>
        <option value="">Normal</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
    </span>
    <span className="ql-formats">
      <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" />
      <button className="ql-blockquote" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
    </span>
    <span className="ql-formats">
      <button className="ql-code-block" />
      <button className="ql-clean" />
    </span>
  </div>
)

export const RichTextEditor = ({ value, setValue, noHtml }) => {
  /**
   *  se noHtml (es.: in Text di Button) => uso value
   *  altrimenti (Text normale) => uso state
   */
  const [state, setState] = useState()

  useEffect(() => {
    if (!noHtml) {
      if (value.endsWith('<p><br></p>')) {
        const state = value
        setState(state.concat('<p><br></p>'))
      } else {
        setState(value)
      }
    }
  }, [])

  const onChange = (value, delta, source, editor) => {
    if (source === 'user') {
      if (!noHtml) {
        setState(noHtml ? editor.getText(value) : value)
      }
      setValue(noHtml ? editor.getText(value) : value)
    }
  }

  return (
    <ReactQuill
      className={`rte-fixed ${noHtml && 'rte-no-toolbar'}`}
      theme="snow"
      value={noHtml ? value : state}
      onChange={onChange}
      modules={modules}
      formats={formats}
      // readOnly={true}
    />
  )
}
