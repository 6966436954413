import React, { useEffect, useState } from 'react'
import { useProdottiConsigliatiAllQuery } from 'gql/graphql'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'

import { defaultProps } from './index'
import { Loader } from '../../pb-components/atoms'

export const ProdottoBoxSettings = (props) => {

  const { 
    data: prodottiConsigliatiData, 
    loading: prodottiConsigliatiLoading,
    error: prodottiConsigliatiError 
  } = useProdottiConsigliatiAllQuery({
    fetchPolicy: 'no-cache',
  })

  const [prodottoOpts, setProdottoOpts] = useState(undefined)

  useEffect(() => {
    if (prodottiConsigliatiData){
      setProdottoOpts(
        prodottiConsigliatiData.prodottiConsigliati.edges.map(x => ({
          label: x.node.titolo,
          value: x.node.pk,
        }))
      )
    }
  }, [prodottiConsigliatiData])

  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        {prodottiConsigliatiLoading || (prodottoOpts === undefined) ? (
          <Loader />
        ) : (
          prodottiConsigliatiError ? (
            <div className="error-message">
              <p>Caricamento prodotti non riuscito</p>
            </div>
          ) : (
            prodottoOpts.length > 0 ? (
              <ToolbarItem
                propKey="block"
                propSubKey="prodottoId"
                type="select"
                label="Prodotto"
                options={prodottoOpts}
                nullable
                defaultValue={defaultProps['prodottoId']}
              />
            ) : (
              <div className="empty-message empty-message--small">
                <p>Nessun prodotto trovato</p>
              </div>
            )
        ))}
      </ToolbarSection>
    </>
  );
};

