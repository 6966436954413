import React, { useEffect, useRef } from 'react'
import { useTrans } from 'hooks'
import { Close, Search } from 'components/atoms/Icons'
import styles from './SearchBar.module.sass'
import useGTM from '../../../hooks/useGTM'

interface Props {
  value?: string
  setValue?: (v: string) => void
  active?: boolean
  setActive?: (v: boolean) => void
  desktopOpen?: boolean
  className?: string
}

const SearchBar = (props: Props) => {
  const {
    value = '',
    setValue = () => undefined,
    active = false,
    setActive = () => undefined,
    desktopOpen = true,
    className = '',
  } = props

  const t = useTrans()
  const { trackingGAevent } = useGTM()
  const inputRef = useRef(null)

  useEffect(() => {
    if (active && inputRef) inputRef.current?.focus()
  }, [active])

  return (
    <div
      className={`${styles.search} ${
        desktopOpen ? styles['search--desktop-open'] : ''
      } ${className}`}
    >
      <form action="/ricerca" onSubmit={() => trackingGAevent('10', 'search', value)}>
        <div className={styles.inputWrap}>
          <input
            type="text"
            name="q"
            value={value}
            placeholder={t('Cosa stai cercando?')}
            onFocus={() => setActive(true)}
            onChange={(e) => setValue(e.target.value)}
            className={styles.input}
            ref={inputRef}
          />
          {active || value ? (
            <button
              type="button"
              className={styles.submit}
              onClick={(e) => {
                e.preventDefault()
                setActive(false)
                setValue('')
              }}
            >
              <Close />
            </button>
          ) : (
            <>
              {/* Di fatto non fa nulla perchè è visibile solo quando la query è vuota */}
              <span className={styles.submit}>
                <Search />
              </span>
            </>
          )}
        </div>
      </form>
    </div>
  )
}

export default SearchBar
