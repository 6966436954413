import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Instagram({ className }: Props) {
  return (
    <IconBase name="instagram" w={24} className={className}>
      <g clipPath="url(#instagram-clip0)">
      <mask id="instagram-mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <path d="M0.000244141 0H23.9999V24H0.000244141V0Z" fill="currentColor"/>
      </mask>
      <g mask="url(#instagram-mask0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M18.4062 4.155C17.6102 4.155 16.9652 4.8 16.9652 5.595C16.9652 6.39 17.6102 7.035 18.4062 7.035C19.2012 7.035 19.8442 6.39 19.8442 5.595C19.8442 4.8 19.2012 4.155 18.4062 4.155ZM12.0002 16C9.79124 16 8.00024 14.21 8.00024 12C8.00024 9.791 9.79124 8 12.0002 8C14.2092 8 16.0002 9.791 16.0002 12C16.0002 14.21 14.2092 16 12.0002 16ZM12.0002 5.838C8.59724 5.838 5.83824 8.597 5.83824 12C5.83824 15.403 8.59724 18.163 12.0002 18.163C15.4032 18.163 18.1622 15.405 18.1622 12C18.1622 8.597 15.4032 5.838 12.0002 5.838ZM12.0002 0C8.74124 0 8.33324 0.014 7.05324 0.072C2.69524 0.272 0.273244 2.69 0.0732441 7.052C0.0142441 8.333 0.000244141 8.741 0.000244141 12C0.000244141 15.259 0.0142441 15.668 0.0722441 16.948C0.272244 21.306 2.69024 23.728 7.05224 23.928C8.33324 23.986 8.74124 24 12.0002 24C15.2592 24 15.6682 23.986 16.9482 23.928C21.3022 23.728 23.7302 21.31 23.9272 16.948C23.9862 15.668 24.0002 15.259 24.0002 12C24.0002 8.741 23.9862 8.333 23.9272 7.053C23.7322 2.699 21.3102 0.273 16.9492 0.073C15.6682 0.014 15.2592 0 12.0002 0ZM12.0002 2.163C15.2042 2.163 15.5842 2.175 16.8492 2.233C20.1012 2.381 21.6212 3.924 21.7682 7.152C21.8272 8.417 21.8382 8.797 21.8382 12.001C21.8382 15.206 21.8262 15.585 21.7682 16.85C21.6202 20.075 20.1052 21.621 16.8492 21.769C15.5842 21.827 15.2062 21.839 12.0002 21.839C8.79624 21.839 8.41624 21.827 7.15124 21.769C3.89124 21.62 2.38024 20.07 2.23224 16.849C2.17424 15.584 2.16224 15.205 2.16224 12C2.16224 8.796 2.17524 8.417 2.23224 7.151C2.38124 3.924 3.89624 2.38 7.15124 2.232C8.41724 2.175 8.79624 2.163 12.0002 2.163Z" fill="currentColor"/>
      </g>
      </g>
      <defs>
      <clipPath id="instagram-clip0">
      <rect width="24" height="24" fill="currentColor"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default Instagram
