import React, { ReactNode } from 'react'
import { Page } from 'components/organisms'
import styles from './ThankYouPage.module.sass'
import { WContainer } from '@components/atoms'

interface ButtonsProp {
  children?: ReactNode
}

interface Props {
  title?: string
  text?: string
  icon?: ReactNode
  className?: string
  children?: ReactNode
  buttons?: ReactNode[]
}

const ThankYouPageButtons = (props: ButtonsProp) => {
  const { children = null } = props

  return <div className={styles.buttons}>{children}</div>
}

const ThankYouPage = (props: Props) => {
  const {
    title = '',
    text = '',
    icon = null,
    className = '',
    children = null,
    buttons = null,
  } = props

  return (
    <Page pagina={{}} item={{}} contentClassName={`${styles.thankYouPage} ${className}`}>
      <WContainer>
        <div className={styles.content}>
          {icon && <span className={styles.icon}>{icon}</span>}
          {title && <p className={styles.title}>{title}</p>}
          {text && <div className={styles.text}>{text}</div>}
          {children && <div className={styles.children}>{children}</div>}
          {buttons && buttons.length > 0 && <ThankYouPageButtons>{buttons}</ThankYouPageButtons>}
        </div>
      </WContainer>
    </Page>
  )
}

ThankYouPage.Buttons = ThankYouPageButtons

export default ThankYouPage
