import React, { useState } from 'react'
import useTrans from '@hooks/useTrans'
import { ChevronLeft } from '@components/atoms/Icons'
import { Button } from '@components/atoms'
import { MyAccountMenu } from '@components/molecules'
import { ModalAggiungiFiglio } from '@components/organisms'
import styles from './MyAccountLayout.module.sass'


interface Props {
  titolo?: string
  descrizione?: string
  modalAggiungiFiglioOpen?: boolean
  setModalAggiungiFiglioOpen?: (v: boolean) => void
  menuActiveItemKey?: string
  backLink?: boolean
  children?: React.ReactNode
  className?: string
}

const MyAccountLayout = (props: Props) => {

  const { 
    titolo = '',
    descrizione = '',
    modalAggiungiFiglioOpen = undefined,
    setModalAggiungiFiglioOpen = () => undefined,
    menuActiveItemKey = 'dashboard',
    children = null,
    className = '',
    backLink = false,
  } = props

  const t = useTrans()

  const [modalAggingiFiglioLocalOpen, setModalAggiungiFiglioLocalOpen] = 
    typeof modalAggiungiFiglioOpen !== "undefined" && typeof setModalAggiungiFiglioOpen !== 'undefined'
    ? [modalAggiungiFiglioOpen, setModalAggiungiFiglioOpen]
    : useState(false)

  return (
    <>
      <div className={`${styles.myAccountLayout} ${className}`}>
        <div className={styles.sidebar}>
          <MyAccountMenu 
            setModalAggiungiFiglioOpen={setModalAggiungiFiglioLocalOpen}
            activeItemKey={menuActiveItemKey} 
          />
        </div>
        <div className={styles.main}>
          {!!backLink && (
            <Button 
              icon={<ChevronLeft />}
              iconPos="left"
              label={t("Torna alla bacheca")} 
              variant="ghost"
              href={'/dashboard'} 
              className={styles.backButton} 
            />
          )}
          {!!titolo && <h1 className={styles.title}>{titolo}</h1>}
          {!!descrizione && <div className={styles.description} dangerouslySetInnerHTML={{ __html: descrizione }} />}
          {children}
        </div>
      </div>
      <ModalAggiungiFiglio open={modalAggingiFiglioLocalOpen} setOpen={setModalAggiungiFiglioLocalOpen} />
    </>
  )
}

export default MyAccountLayout
