import { Formik } from 'formik'
import React, { useRef, useState } from 'react'
import * as yup from 'yup'
import useTrans from '@hooks/useTrans'
import { useCreateRichiestaContattoEventoMutation } from 'gql/graphql'
import { Feedback } from 'components/atoms/Icons'
import styles from './FormContattiEvento.module.sass'
import useGTM from '../../../hooks/useGTM'
import {
  Button,
  FormField,
  FormInput,
  FormCheckbox,
  FormLayout,
  Modal,
  OptinLabel,
} from 'components/atoms'

const validationSchema = (t) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t("Il formato dell'email dev'essere valido"))
      .nullable()
      .required(t('Questo campo non può essere vuoto')),
    privacy: yup
      .boolean()
      .required(t('Questo campo non può essere vuoto'))
      .oneOf([true], t('Devi accettare i Termini e le Condizioni')),
  })

interface Props {
    titolo?: string
    evento?: number
}

const FormContattiEvento = (props: Props) => {
  const {titolo = '', evento = null} = props
  const t = useTrans()
  const [open, setOpen] = useState(false)
  const { trackingGAevent } = useGTM()
  const [createRichiesta, { loading }] = useCreateRichiestaContattoEventoMutation()
  return (
    <div className={styles.containerForm}>
      <p className={styles.title}>{t('Iscriviti e partecipa anche tu all\’evento')} {titolo}</p>
    
    <Formik
      validationSchema={validationSchema(t)}
     
      initialValues={{
        email: '',
        privacy: false,
        evento : evento,
       
      }}
  
      onSubmit={async (values, { resetForm }) => {
        createRichiesta({
          variables: {
            input: {
              ...values,
            
            },
          },
        })
          .then((res) => {
            trackingGAevent('', 'live_shopping', 'subscription', 'success')
            res.data?.createRichiestaContattoEvento?.status && (setOpen(true), resetForm())
          })
          .catch((err) => {
            console.log(err)
          })
      
      }}
    >
      {(props) => (
         <>
        <FormLayout>
          <FormField
            label={t('LA TUA EMAIL')}
            status={props.touched.email ? (props.errors.email ? 'error' : 'success') : null}
            errorMessage={props?.errors?.email}
            color="white" layout="full"
          >
            <FormInput
              onBlur={props.handleBlur('email')}
              placeholder="La tua email"
              value={props.values.email || ''}
              onChange={props.handleChange('email')}
            />
            <FormInput
              value={props.values.evento}
              className={styles.invisible}
            />
          </FormField>
          <FormField
            status={props.touched.privacy ? (props.errors.privacy ? 'error' : 'success') : null}
            errorMessage={props.errors.privacy}
            layout="full"
          >
            <FormCheckbox
              id="privacy"
              checked={props.values?.privacy}
              color="white"
              onChange={props.handleChange('privacy')}
              label={<OptinLabel type="informativa_evento" />}
              className={styles.checkbox}
            />
          </FormField>
         
          </FormLayout>
      
          <div className={styles.submitBox}>
            <Button
              loading={loading}
              disabled={!props.isValid}
              onClick={() => props.handleSubmit()}
              variant="primary"
              label={t('Iscriviti all\'evento')}
            />
          </div>
          </>
      
        
      )}
    </Formik>
        <Modal open={open} setOpen={setOpen}>
        <Modal.Content
          icon={<Feedback />}
          title={t('Grazie per esserti iscritt* all’evento')}
          // description={t(
          //   'Abbiamo ricevuto correttamente la tua domanda e ti risponderemo il prima possibile. Troverai nella tua casella di posta una mail di conferma con il riepilogo dei tuoi dati.'
          // )}
        />
        <Modal.Buttons>
          <Button variant="ghost" label={t('Torna alla home')} href={'/'} />
        </Modal.Buttons>
      </Modal>
      </div>
  )
}

export default FormContattiEvento
