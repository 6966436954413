import React from 'react'
import useTrans from 'hooks/useTrans'
import { ChevronLeft } from 'components/atoms/Icons'
import {
  Button,
  FormField,
  FormInput,
  FormLayout,
  FormRadio,
  FormStep,
  FormLegend,
  FormDate,
} from 'components/atoms'
import { StepProps } from '../FormRegistrazione.interfaces'

const DatiPersonali = ({ setSteps, formik }: StepProps) => {
  const t = useTrans()

  return (
    <FormStep title={t('I tuoi dati di accesso')}>
      <FormStep.Content>
        <FormLayout>
          <FormField
            label={t('Sono*')}
            errorMessage={formik.errors.dati_personali?.sono}
            status={formik.errors.dati_personali?.sono ? 'error' : null}
          >
            <FormRadio
              id="dati_personali.sono"
              value={formik.values.dati_personali?.sono}
              onBlur={formik.handleBlur('dati_personali.sono')}
              onChange={formik.handleChange('dati_personali.sono')}
              options={[
                {
                  id: 'mamma',
                  label: t('Mamma'),
                  value: 'F',
                },
                {
                  id: 'papà',
                  label: t('Papà'),
                  value: 'M',
                },
                {
                  id: 'in_dolce_attesa',
                  label: t('In dolce attesa'),
                  value: 'X',
                },
              ]}
            />
          </FormField>

          <FormField label={t('Cellulare')}>
            <FormInput
              type="number"
              onChange={formik.handleChange('dati_personali.cellulare')}
              onBlur={formik.handleBlur('dati_personali.cellulare')}
              value={formik.values?.dati_personali?.cellulare}
            />
          </FormField>

          <FormField
            label={t('Data di nascita')}
            errorMessage={
              formik.errors.dati_personali?.data_nascita && t('Inserire una data valida')
            }
            status={
              formik.touched.dati_personali?.data_nascita
                ? formik.errors.dati_personali?.data_nascita
                  ? 'error'
                  : 'success'
                : null
            }
          >
            <FormDate
              onBlur={formik.handleBlur('dati_personali.data_nascita')}
              status={formik.errors.dati_personali?.data_nascita ? 'error' : ''}
              value={formik.values.dati_personali?.data_nascita}
              onChange={(value) => formik.setFieldValue('dati_personali.data_nascita', value)}
            />
          </FormField>
        </FormLayout>
        <FormLegend label={t('* Campi obbligatori')} />
      </FormStep.Content>
      <FormStep.Buttons>
        <Button
          label={t('Indietro')}
          onClick={() => setSteps((prev: number) => prev - 1)}
          variant="secondary"
          icon={<ChevronLeft />}
          iconPos="left"
        />
        <Button
          label={t('Avanti')}
          disabled={!!formik.errors.dati_personali}
          onClick={() => setSteps((prev: number) => prev + 1)}
          variant="primary"
        />
      </FormStep.Buttons>
    </FormStep>
  )
}

export default DatiPersonali
