import { Formik } from 'formik'
import React, { useState } from 'react'
import * as yup from 'yup'
import Link from 'next/link'
import { useLoginMutation } from '@gql/graphql'
import { useRouter } from 'next/router'
import useTrans from '@hooks/useTrans'
import useLocalStorage from '@hooks/useLocalStorage'
import {
  Button,
  FormCheckbox,
  FormField,
  FormInput,
  FormLayout,
  FormInputPassword,
} from 'components/atoms'
import styles from './FormLogin.module.sass'
import useGTM from '../../../hooks/useGTM'

const validationSchema = (t) =>
  yup.object().shape({
    email: yup.string().email(t("Il formato dell'email dev'essere valido")).required().nullable(),
    password: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    ricordati: yup.boolean().nullable(),
  })

const FormLogin = ({
  nextPage,
  iframe,
  from,
  email,
}: {
  nextPage: ''
  iframe?: boolean
  from?: string
  email?: string
}) => {
  const [login, { loading }] = useLoginMutation()
  const [error, setError] = useState<string | undefined | null>()
  const [, setUserId] = useLocalStorage('userId', null)
  const [, setToken] = useLocalStorage('token', null)
  const { trackingGAevent } = useGTM()

  const router = useRouter()
  const t = useTrans()

  const initialValues = {
    email: email,
    password: null,
    ricordati: null,
  }

  return (
    <Formik
      isInitialValid={false}
      validateOnChange
      onSubmit={(values) =>
        login({
          variables: {
            email: values.email,
            password: values.password,
          },
        })
          .then((res) => {
            trackingGAevent('6', 'login', 'standard', 'success')

            let redirect_url = undefined
            const status_code = res.data?.tokenAuth?.data?.status_code

            switch (status_code) {
              case 200:
                redirect_url = nextPage || '/dashboard'
                // save token and gui
                setToken(res.data?.tokenAuth?.data?.content?.tokenObject?.token)
                setUserId(res.data?.tokenAuth?.data?.content?.UID)
                break
              case 401:
                redirect_url = '/resend-activation'
                setError(null)
                break
              case 403:
                setError('Le credenziali inserite non sono corrette.')
                break
              default:
                setError('Si è verificato un errore, ti preghiamo di riprovare più tardi')
            }

            if (redirect_url !== undefined) {
              iframe
                ? from === 'ariele'
                  ? (window.location.href = `https://ariele.psicosocioanalisi.it/public/?nome=${res?.data?.tokenAuth?.user?.nome}&cognome=${res?.data?.tokenAuth?.user?.cognome}&email=${res.data?.tokenAuth?.user?.email}`)
                  : (window.location.href = `https://www.patronato.acli.it/consulenza-mellin/?cognome=${res?.data?.tokenAuth?.user?.cognome}&nome=${res?.data?.tokenAuth?.user?.nome}&email=${res.data?.tokenAuth?.user?.email}&prres=CITTA`)
                : router.push(redirect_url)
            }
          })
          .catch(() => {
            trackingGAevent('6', 'login', 'standard', 'error')
            setError('Si è verificato un errore, ti preghiamo di riprovare più tardi')
          })
      }
      initialValues={initialValues}
      validationSchema={validationSchema(t)}
      enableReinitialize
    >
      {(props) => (
        <>
          <FormLayout>
            <FormField
              label="Email"
              layout="full"
              status={props.touched.email ? (props.errors.email ? 'error' : 'success') : null}
              errorMessage={props?.errors?.email as string}
            >
              <FormInput
                onBlur={props.handleBlur('email')}
                placeholder="Email"
                value={props.values.email}
                onChange={props.handleChange('email')}
              />
            </FormField>
            <FormField
              label="Password"
              layout="full"
              status={props.touched.password ? (props.errors.password ? 'error' : 'success') : null}
              errorMessage={props?.errors?.password as string}
            >
              <FormInputPassword
                togglePassword={false}
                placeholder="Password"
                onBlur={props.handleBlur('password')}
                value={props.values.password}
                onChange={props.handleChange('password')}
              />
            </FormField>
          </FormLayout>
          <p className={styles.error}>{error}</p>
          <div className={styles.helpers}>
            <FormCheckbox
              label="Ricordati di me"
              id="ricordati"
              checked={props.values.ricordati}
              onChange={(e) => props.setFieldValue('ricordati', e.target.checked)}
            />
            <Link
              legacyBehavior
              href={
                iframe
                  ? `/recupera-password-parto-riparto?f=${encodeURIComponent(from)}`
                  : '/recupera-password'
              }
            >
              <a>{t('Hai dimenticato la password?')}</a>
            </Link>
          </div>
          <Link legacyBehavior href="/resend-activation">
            <a className={styles.resendActivation}>
              {t("Non hai ricevuto l'email di attivazione?")}
            </a>
          </Link>
          <Button
            label="Accedi"
            onClick={() => props.handleSubmit()}
            disabled={Object.values(props.errors).length > 0 || loading}
            className={styles.submit}
          />
        </>
      )}
    </Formik>
  )
}

export default FormLogin
