import React, { useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import dayjs from 'dayjs'
import { DatiAccesso, DatiPersonali, TuoBambino } from './steps/index'
import { IinitialVaues } from './FormRegistrazione.interfaces'
import { StepBar } from '@components/atoms'
import styles from './FormRegistrazione.module.sass'
import { useCreateUtenteMutation } from '@gql/graphql'
import { useRouter } from 'next/router'
import useTrans from '@hooks/useTrans'
import useGTM from '../../../hooks/useGTM'

const validationSchema = (t, from, iframe) =>
  yup.object().shape({
    dati_accesso: yup.object().shape({
      nome: yup.string().nullable().required(t('Questo campo non può essere vuoto')),
      cognome: yup.string().nullable().required(t('Questo campo non può essere vuoto')),
      email: yup
        .string()
        .email(t("Il formato dell'email dev'essere valido"))
        .nullable()
        .required(t('Questo campo non può essere vuoto')),
      password: iframe
        ? yup.string().nullable()
        : yup
            .string()
            .nullable()
            .required(t('Questo campo non può essere vuoto'))
            .min(8, t('Minimo 8 Caratteri'))
            .matches('^(?=.*?[A-Z])', 'Questo campo deve contenere almeno una lettera maiuscola.'),
      confirm_password: iframe
        ? yup.string().nullable()
        : yup
            .string()
            .nullable()
            .required(t('Questo campo non può essere vuoto'))
            .oneOf([yup.ref('password')], 'Passwords does not match'),
    }),
    dati_personali: yup.object().shape({
      sono: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
      data_nascita: yup.date().required(t('Questo campo non può essere vuoto')),
      cellulare: yup.number().nullable(),
    }),
    tuo_bambino: yup.object().shape({
      nome_bambino: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
      data_bambino: yup.date().required(t('Questo campo non può essere vuoto')),
      sesso_bambino: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
      gemelli: yup.boolean(),
    }),
    datiParticolari: yup.boolean(),
    privacy_commerciale: yup.boolean(),
    privacy_profilazione: yup.boolean(),
    privacy_informativa: yup
      .boolean()
      .required(t('Questo campo non può essere vuoto'))
      .oneOf([true], t('Devi accettare i Termini e le Condizioni')),
  })

const RenderSteps = ({ steps, setSteps, formik, from }) => {
  switch (steps) {
    case 0:
      return <DatiAccesso formik={formik} setSteps={setSteps} from={from} />
    case 1:
      return <DatiPersonali formik={formik} setSteps={setSteps} />
    case 2:
      return <TuoBambino formik={formik} setSteps={setSteps} />
    default:
  }
}

const FormRegistrazione = ({
  initialStep,
  iframe,
  from,
}: {
  initialStep?: number | any
  iframe?: boolean
  from?: string
}) => {
  const [steps, setSteps] = useState(initialStep || 0)
  const [createUtente] = useCreateUtenteMutation()

  const router = useRouter()
  const t = useTrans()
  const { trackingGAevent } = useGTM()

  const initialValues: IinitialVaues = {
    dati_accesso: {
      nome: null,
      cognome: null,
      email: (router.query?.email as string) || null,
      password: null,
      confirm_password: null,
    },
    dati_personali: {
      data_nascita: '1980-01-01',
      cellulare: null,
      sono: null,
    },
    tuo_bambino: {
      data_bambino: dayjs().format('YYYY-MM-DD'),
      sesso_bambino: null,
      nome_bambino: null,
      gemelli: false,
    },
    datiParticolari: false,
    privacy_commerciale: false,
    privacy_profilazione: false,
    privacy_informativa: false,
  }

  return (
    <div>
      <StepBar
        activeStepIndex={steps + 1}
        steps={['Dati di accesso', 'Dati personali', 'Il tuo bambino']}
        className={styles.stepbar}
      />
      <Formik
        isInitialValid={false}
        validateOnBlur
        validateOnChange
        validationSchema={validationSchema(t, from, iframe)}
        onSubmit={(values) =>
          createUtente({
            variables: {
              input: {
                firstName: values?.dati_accesso?.nome,
                lastName: values?.dati_accesso?.cognome,
                fullName: `${values?.dati_accesso?.nome} ${values?.dati_accesso?.cognome}`,
                gender: values?.dati_personali?.sono,
                email1: values?.dati_accesso?.email,
                password: values?.dati_accesso?.password,
                birthday: dayjs(values?.dati_personali?.data_nascita).format('YYYY-MM-DD'),
                phoneMobile: values?.dati_personali?.cellulare?.toString(),
                children: [
                  {
                    firstName: values?.tuo_bambino?.nome_bambino,
                    gender: values?.tuo_bambino?.sesso_bambino,
                    dateOfBirth: dayjs(values?.tuo_bambino?.data_bambino).format('YYYY-MM-DD'),
                    isTwin: values?.gemelli,
                  },
                ],
                optins: {
                  optinMarketing: values?.privacy_commerciale,
                  optinProgramMarketing1: values?.datiParticolari,
                  optinProgramMarketing2: values?.privacy_profilazione,
                },
              },
            },
          })
            .then((response) => {
              trackingGAevent('5', 'signup', 'standard', 'success')

              const data = response.data.createUtente.content
              let redirect_url = '/registrazione/registration-error'
              if (data.status_code === 200) {
                redirect_url = '/registrazione/thank-you'
              } else if (data.status_code === 409) {
                redirect_url = `/login?email=${values?.dati_accesso?.email}`
              }

              iframe
                ? from === 'ariele'
                  ? (window.location.href = `https://ariele.psicosocioanalisi.it/public/?nome=${values?.dati_accesso?.nome}&cognome=${values?.dati_accesso?.cognome}&email=${values?.dati_accesso?.email}`)
                  : (window.location.href = `https://www.patronato.acli.it/consulenza-mellin/?cognome=${values?.dati_accesso?.cognome}&nome=${values?.dati_accesso?.nome}&email=${values?.dati_accesso?.email}&prres=CITTA`)
                : router.push(redirect_url)
            })
            .catch(() => {
              trackingGAevent('5', 'signup', 'standard', 'error')
              iframe
                ? from === 'ariele'
                  ? (window.location.href = `https://ariele.psicosocioanalisi.it/public/?nome=${values?.dati_accesso?.nome}&cognome=${values?.dati_accesso?.cognome}&email=${values?.dati_accesso?.email}`)
                  : (window.location.href = `https://www.patronato.acli.it/consulenza-mellin/?cognome=${values?.dati_accesso?.cognome}&nome=${values?.dati_accesso?.nome}&email=${values?.dati_accesso?.email}&prres=CITTA`)
                : router.push('/registrazione/registration-error')
            })
        }
        initialValues={initialValues}
        enableReinitialize
      >
        {(props) => <RenderSteps formik={props} steps={steps} setSteps={setSteps} from={from} />}
      </Formik>
    </div>
  )
}

export default FormRegistrazione
