import React from 'react'
import { Image } from 'react-bootstrap'
import useLocalStorage from "@hooks/useLocalStorage";

export interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  item?: {
    name?: string
    image?: string
    pk?: string
    url: string
  }
  className?: string
}

const BrandButton = (props: Props) => {
  const { item = {}, className = '' } = props
  const [token, _] = useLocalStorage('token', null)

  return (
    <a
      className={className}
      href={
        token ? `${item.url}?jwt=${token}` : `${item.url}`
      }
      target="_blank"
      rel="noopener noreferrer"
    >
      <Image alt={item.name} src={item.image} id={item.pk} />
    </a>
  )
}

export default BrandButton
