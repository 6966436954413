import React from 'react'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { defaultProps } from './index'

export const QuoteBoxSettings = (props) => {

  return (
    <>
      <ToolbarSection title="Image" initOpen>
        {!props.floating && (
          <>
            <ToolbarItem
              propKey="block"
              propSubKey="showImage"
              type="switch"
              label="Mostra immagine"
              defaultValue={defaultProps['showImage']}
            />
            <ToolbarItem
              propKey="block"
              propSubKey="src"
              type="text"
              label="Src"
              description="Paste your url or upload a file"
              defaultValue={defaultProps['src']}
            />
            <ToolbarItem propKey="block" propSubKey="image" type="file" />
          </>
        )}
        <ToolbarItem
          propKey="block"
          propSubKey="alt"
          type="text"
          label="Alt Text"
          defaultValue={defaultProps['alt']}
        />
      </ToolbarSection>
    </>
  );
};

export const QuoteBoxFloatingSettings = () =>
  React.createElement(QuoteBoxSettings, { floating: true });
