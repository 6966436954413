import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Check({ className }: Props) {
  return (
    <IconBase name="check" w={18} className={className}>
      <path d="M2.625 8.625L7.125 13.125L15.375 4.875" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    </IconBase>
  )
}

export default Check
