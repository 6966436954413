import React, { ReactNode, useEffect } from 'react'
import { Close } from 'components/atoms/Icons'
import styles from './Modal.module.sass'

interface ContentProps {
  icon?: ReactNode
  title?: string
  description?: string
  children?: ReactNode
}

interface ButtonsProps {
  children?: ReactNode
}

interface Props {
  open: boolean
  setOpen: (v: boolean) => void
  closeButton?: boolean
  scrollable?: boolean
  className?: string
  children?: ReactNode
}

const ModalContent = (props: ContentProps) => {
  const { icon = null, title = '', description = '', children = null } = props

  return (
    <div className={styles.content}>
      {(icon || title || description) && (
        <div className={styles.head}>
          {icon && <p className={styles.icon}>{icon}</p>}
          {title && <p className={styles.title}>{title}</p>}
          {description && <p className={styles.description}>{description}</p>}
        </div>
      )}
      {children}
    </div>
  )
}

const ModalButtons = (props: ButtonsProps) => {
  const { children = null } = props

  return <div className={styles.buttons}>{children}</div>
}

const Modal = (props: Props) => {
  const {
    open = false,
    setOpen = () => {},
    closeButton = false,
    scrollable = false,
    className = '',
    children = null,
  } = props

  useEffect(() => {
    if (open)
      document.body.classList.add('w-noscroll')
    else
      document.body.classList.remove('w-noscroll')
  }, [open])

  const close = (e: any) => {
    e.stopPropagation()
    if (setOpen) setOpen(false)
  }

  return open ? (
    <>
      <div className={styles.overlay} />
      <div className={`${styles.modal} ${scrollable ? styles['modal--scrollable'] : ''} ${className}`}>
        {closeButton && (
          <button type="button" className={styles.close} onClick={close}>
            <Close />
          </button>
        )}
        <div className={styles.wrap}>
          <div className={styles.body}>{children}</div>
        </div>
      </div>
    </>
  ) : (
    <></>
  )
}

Modal.Content = ModalContent
Modal.Buttons = ModalButtons

export default Modal
