import { useContext } from 'react'
import { PagebuilderContext } from '../services/pagebuilderContext'
import { GlobalContext } from '../services/globalContext'
import { Editor } from '../editor'
import { Modal, Loader } from 'react-pagebuilder/pb-components/atoms'
import { PlaceholderImport } from './PlaceholderImport'


export const Placeholder = () => {
  const { utente, editMode, draft, showImport, setShowImport } = useContext(GlobalContext)

  const { pagebuilder, isLoading } = useContext(PagebuilderContext)

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        pagebuilder && (
          <div className="pagebuilder-wrapper" style={{ width: '100%', height: '100%' }}>
            {(editMode ||
              (!editMode &&
                (pagebuilder.json_published ||
                  (draft && utente && utente.isStaff && pagebuilder.json_draft)))) && (
              <Editor
                json={
                  editMode
                    ? pagebuilder.json_draft
                    : draft !== undefined && draft !== null && utente && utente.isStaff
                    ? draft
                      ? pagebuilder.json_draft
                      : pagebuilder.json_published
                    : pagebuilder.json_published
                }
                editMode={editMode}
                withConditions={true}
              />
            )}
            {editMode && showImport && (
              <Modal open={true} setOpen={setShowImport}>
                <PlaceholderImport />
              </Modal>
            )}
          </div>
        )
      )}
    </>
  )
}
